import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import config from '../../../config';
import useReactRouter from 'use-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { actions as ScormActions } from '../../../redux/ducks/scorm';
import { Grid, IconButton, InputBase, Container, AppBar, Toolbar, Typography } from '@material-ui/core';
import { QrMenu } from '../../../components/QrMenu';
import QrContent from '../../../components/QrContent';
import EmptyContent from '../../../components/EmptyContent';
import ErrorContent from '../../../components/ErrorContent';
import CategoryItem from './components/CategoryItem';
import { LoadingCategoryList } from '../../../components/Loading';
import { Icon } from '../../../components/Images/Images';

export default function ScormList(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const { scorms } = useSelector(state => state.scorm);
    const [didMount, setDidMount] = useState(false);
    const [search, setSearch] = useState("");
    const [searchOpen, setSearchOpen] = useState(false);
    const [filteredList, setFilteredList] = useState([]);
    const theme = props.location.state && props.location.state.theme ? props.location.state.theme : '';
    const origin = props.location.state && props.location.state.origin ? props.location.state.origin : '';

    useEffect(() => {
        setDidMount(true);

        if (didMount) {
            setTimeout(() => {
                dispatch(ScormActions.getScormList({
                    id_category: theme.id_category
                }));
            }, 500);
        }

        return () => {
            dispatch(ScormActions.reset());
            setDidMount(false);
        }
    }, [didMount, dispatch, theme]);

    useEffect(() => {
        setFilteredList(
            (scorms && scorms.data) && scorms.data.filter((item) =>
                item.title.toLowerCase().includes(search.toLowerCase())
            )
        );
    }, [search, scorms]);

    function goBack() {
        let currentOrigin = "scorm_list";

        switch (origin) {
            case 'list':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/scorm`,
                    state: {
                        origin: currentOrigin
                    }
                })
            case 'details':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/scorm`,
                    state: {
                        origin: currentOrigin
                    }
                })
            default:
                return history.goBack()
        }
    }

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-default" position="sticky" color="primary">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="back"
                        edge="start"
                        onClick={() => goBack()}
                    >
                        <Icon ic="back" />
                    </IconButton>
                    <div className="w-100 overflow-hidden">
                        {
                            searchOpen ?
                                null
                                :
                                theme ?
                                    <div>
                                        <Typography variant="h4" noWrap>
                                            {theme.title}
                                        </Typography>
                                        <div className="w-100 d-flex overflow-hidden">
                                            <Typography variant="body2" noWrap>
                                                {intl.get("SCORM_TITLE")}
                                            </Typography>
                                        </div>
                                    </div>
                                    :
                                    <Typography variant="h4" noWrap>
                                        {intl.get('SCORM_TITLE')}
                                    </Typography>
                        }

                    </div>
                    {
                        searchOpen ?
                            <div className="appbar-search">
                                <InputBase
                                    className="appbar-search-bar"
                                    fullWidth
                                    placeholder={intl.get("SEARCH_COMPONENT")}
                                    autoFocus
                                    color='primary'
                                    variant="filled-basic"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    inputProps={{ 'aria-label': 'search' }}
                                />
                                <IconButton onClick={() => { setSearch(""); setSearchOpen(false); }} className="ml-2">
                                    <Icon ic="cancel" />
                                </IconButton>
                            </div>
                            :
                            <IconButton onClick={() => setSearchOpen(!searchOpen)}>
                                <Icon ic="search" />
                            </IconButton>
                    }
                </Toolbar>
            </AppBar>
        )
    }

    const ScormList = () => {
        if (filteredList && filteredList.length > 0) {
            return (
                filteredList.map((item, index) => {
                    let imgFileData = item.image.blob ? {
                        'storage_type': item.image.blob.storage_type,
                        'blob_container': item.image.blob.blob_container,
                        'file_path': item.image.blob.file_name
                    } : {
                        'source': item.image.source
                    }

                    return (
                        <Grid item key={index} xs={6} sm={3} md={4} lg={3}>
                            <CategoryItem
                                action={() => history.push({ pathname: `${props.match.path.replace(/./g, '')}/scorm/${item.id_scorm}/details`, state: { theme: theme, origin: 'scorm_list' } })}
                                image={imgFileData}
                                title={item.title}
                                status={item.completed}
                                totalCompleted={item.total_package_complete}
                                totalLessons={item.total_package}
                                obligatory={item.obligatory}
                            />
                        </Grid>
                    )
                })
            )
        } else {
            return (
                <EmptyContent icon={<Icon ic="search" className="icon-xxl" />} text={intl.get("NO_RESULTS_FOUND")} />
            )
        }
    }

    return (
        <div id="qr-wrap">
            <QrMenu />
            <div id="qr-content">
                <QrContent id="scorm-list" hideSidebar>
                    {
                        config.layout.navbar ?
                            <QrAppBar />
                            : null
                    }
                    <Container maxWidth="lg" className="pt-3 pb-3">
                        {
                            scorms && scorms.load === "finished" ?
                                !scorms.error ?
                                    scorms.data.length !== 0 ?
                                        <Grid container spacing={2} className="category-list __fluid">
                                            <ScormList />
                                        </Grid>
                                        :
                                        <EmptyContent icon={<Icon ic="play_scorm" className="icon-xxl" />} text={intl.get("EMPTY_SCORMS")} />
                                    :
                                    <ErrorContent />
                                :
                                <LoadingCategoryList limit={8} />
                        }
                    </Container>
                </QrContent>
            </div>
        </div>
    )
}
