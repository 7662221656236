import React from 'react';
import { useSelector } from 'react-redux';
import { MobileBottomNavigation } from './QrMenu';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const QrContent = ({ children, hideSidebar, id, className, bottomNavigation }) => {
    const theme = useTheme();
    const menuOpen = useSelector(state => state.qrLayout.menuOpen);
    const sidebarOpen = useSelector(state => state.qrLayout.sidebarOpen);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div id="qr-middle" className={`sb__${hideSidebar ? 'hidden' : sidebarOpen ? 'open' : 'close'} menu__${menuOpen ? 'open' : 'close'}`}>
            <main id={id} className={`qr-main ${className ? className : ""}`} role="main">
                <div className="qr-main_content">
                    {children}
                    {(() => {
                        if (isMobile && bottomNavigation) {
                            return <MobileBottomNavigation />
                        }
                    })()}
                </div>
            </main>
        </div>
    )
}

export default QrContent