import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../config';
import useReactRouter from 'use-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { actions as AuthActions } from '../../redux/ducks/auth';
import { actions as SettingsActions } from '../../redux/ducks/settings';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import * as yup from 'yup';
import { validateCPF } from 'validations-br';
import { getDateFormat, maskCPF } from '../../utils/utils';
import {
    Container, Grid, AppBar, Toolbar, IconButton, Typography,
    TextField, Button
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { QrMenu } from '../../components/QrMenu';
import QrContent from '../../components/QrContent';
import SnackBar from '../../components/snackBar';
import { Icon } from '../../components/Images/Images';
import { LoadingBounce } from '../../components/Loading';

export default function RedeemInfo(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const user = useSelector(state => state.auth.user);
    const redeemUpdate = useSelector(state => state.settings.redeemUpdate);
    const [userData, setUserData] = useState({});
    const [loading, setLoading] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackStatus, setSnackStatus] = useState('error');
    const [snackMessage, setSnackMessage] = useState(intl.get('ERROR_UPDATE_DATA'));
    const origin = props.location.state && props.location.state.origin ? props.location.state.origin : '';
    const redirect = props.location.state && props.location.state.redirect ? props.location.state.redirect : '';
    let language = user.language.substr(0, 2);
    let locale = require(`date-fns/locale/${language}`);

    const validations = yup.object().shape({
        birth_date: yup.date()
            .required(() => intl.get('ERROR_REQUIRED_FIELD'))
            .typeError(() => intl.get('ERROR_INVALID_DATE'))
            .nullable(),
        cpf: user.country === "BR" ?
            yup.string()
                .test("is-cpf", intl.get('ERROR_INVALID_CPF'), (value) => validateCPF(value))
                .required(() => intl.get('ERROR_REQUIRED_FIELD'))
            :
            yup.string().required(() => intl.get('ERROR_REQUIRED_FIELD')),
    })

    useEffect(() => {
        if (loading && (userData && redeemUpdate?.data?.id_user)) {
            setLoading(false);
            setSnackStatus('success');
            setSnackOpen(true);
            setSnackMessage(intl.get('SUCCESS_UPDATE_DATA'));
            dispatch(SettingsActions.resetRedeemUpdate({}));
        }

        if (!loading && (userData?.data && !snackOpen)) {
            setUserData({});
            dispatch(AuthActions.updateDataRedeem({
                birth_date: userData.data.birth_date ? getDateFormat(userData.data.birth_date) : '',
                cpf: userData.data.cpf,
            }));
        }

        if (loading && redeemUpdate?.error === true) {
            setLoading(false);
            setSnackStatus('error');
            setSnackOpen(true);
            setSnackMessage(redeemUpdate.messages);
            dispatch(SettingsActions.resetRedeemUpdate({}));
        }
    }, [loading, redeemUpdate, userData, snackOpen, dispatch, origin]);

    useEffect(() => {
        if (origin === "prizes" && (user.cpf && user.birth_date)) {
            history.push({
                pathname: redirect === "Y" ? `${props.match.path.replace(/./g, '')}/settings/data-redeem/address` : `${props.match.path.replace(/./g, '')}/prizes`,
                state: {
                    origin: 'prizes'
                }
            })
        }
    }, [history, props.match.path, redirect, origin, user]);

    const sendData = (data) => {
        setLoading(true);
        setUserData({ data: { ...userData, ...data } });
        dispatch(SettingsActions.setRedeemUpdate({
            cpf: data.cpf,
            birth_date: getDateFormat(data.birth_date),
        }));
    }

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-default" position="sticky" color="primary">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="back"
                        edge="start"
                        onClick={() => history.goBack()}
                    >
                        <Icon ic="back" />
                    </IconButton>
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {intl.get("SETTINGS_DATA_REDEEM_INFO_TITLE")}
                        </Typography>
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

    return (
        <div id="qr-wrap">
            <QrMenu />
            <div id="qr-content">
                <QrContent id="settings-redeem-info" hideSidebar>
                    {
                        config.layout.navbar ?
                            <QrAppBar />
                            : null
                    }
                    <Container maxWidth="md" className="pt-4 pb-4">
                        <Formik
                            validationSchema={validations}
                            initialValues={{
                                cpf: user.cpf ? user.cpf : '',
                                birth_date: user.birth_date ? moment(user.birth_date) : null,
                            }}
                            onSubmit={data => { sendData(data) }}
                        >
                            {
                                ({ handleBlur, handleChange, handleSubmit, errors, touched, setFieldValue, values }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Grid container justify="center" spacing={3}>
                                            <Grid item xs={12} sm={10}>
                                                <TextField
                                                    disabled={user.cpf || userData?.data?.cpf ? true : false}
                                                    name="cpf"
                                                    type="text"
                                                    label={intl.get('LABEL_CPF')}
                                                    variant={config.layout.input_variant}
                                                    value={user.country === "BR" ? maskCPF(values.cpf) : values.cpf}
                                                    fullWidth
                                                    required
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    error={(errors.cpf && touched.cpf)}
                                                    helperText={(errors.cpf && touched.cpf) && errors.cpf}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={10}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                                                    <KeyboardDatePicker
                                                        fullWidth
                                                        required
                                                        id="birth_date"
                                                        name="birth_date"
                                                        margin="none"
                                                        label={intl.get('LABEL_BIRTHDAY')}
                                                        inputVariant={config.layout.input_variant}
                                                        format="dd/MM/yyyy"
                                                        value={values.birth_date}
                                                        onBlur={handleBlur}
                                                        onChange={value => setFieldValue("birth_date", value)}
                                                        KeyboardButtonProps={{
                                                            'aria-label': intl.get('LABEL_BIRTHDAY'),
                                                        }}
                                                        okLabel={intl.get('BTN_CONFIRM')}
                                                        clearLabel={intl.get('BTN_CLEAR')}
                                                        cancelLabel={intl.get('BTN_CANCEL')}
                                                        error={(errors.birth_date && touched.birth_date)}
                                                        helperText={(errors.birth_date && touched.birth_date) && errors.birth_date}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                            <Grid item xs={12} sm={6} lg={4}>
                                                {
                                                    !loading
                                                        ?
                                                        <Button
                                                            id="btn_settings_save"
                                                            variant="contained"
                                                            className="btn-block"
                                                            type="submit"
                                                        >
                                                            {intl.get('BTN_SAVE')}
                                                        </Button>
                                                        :
                                                        <Grid container alignItems="center" justify="center">
                                                            <LoadingBounce />
                                                        </Grid>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                        </Formik>
                    </Container>
                </QrContent>
                <SnackBar
                    open={snackOpen}
                    message={snackMessage}
                    status={snackStatus}
                    time={4}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    closeSnack={() => setSnackOpen(false)}
                />
            </div>
        </div>
    )

}