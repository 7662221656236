import { userService } from '../../services/user.service';
import { loginEvent } from '../../firebase.js';
// import sha256 from 'crypto-js/sha256';
// import md5 from 'crypto-js/md5';

// CONST
export const Types = {
    LOGIN: 'auth/LOGIN',
    ERROR: 'auth/ERROR',
    LOGOUT: 'auth/LOGOUT',
    CREATE_ACCOUNT: 'auth/CREATE_ACCOUNT',
    UPDATE_POINTS: 'auth/UPDATE_POINTS',
    COMPLETE_DATA: 'auth/COMPLETE_DATA',
    UPDATE_PROFILE: 'auth/UPDATE_PROFILE',
    RESET_PROFILE_UPDATE_ERROR: 'auth/RESET_PROFILE_UPDATE_ERROR',
    RECOVER_PASSWORD: 'auth/RECOVER_PASSWORD',
    GET_CODE: 'auth/GET_CODE',
    FIRST_ACCESS: 'auth/FIRST_ACCESS',
    PHOTO_UPDATE: 'auth/PHOTO_UPDATE',
    PREFERENCES_UPDATE: 'auth/PREFERENCES_UPDATE',
    AZURE_SAML: 'auth/AZURE_SAML',
    AZURE_SAML_LOGIN: 'auth/AZURE_SAML_LOGIN',
    JWT: 'auth/jwt',
    LOADING: 'auth/LOADING',
    EXPIRED_PASSWORD_UPDATE: 'auth/EXPIRED_PASSWORD_UPDATE',
    SHOWLOGIN: 'auth/SHOWLOGIN',
    PRIVACYPOLITICS: 'auth/PRIVACYPOLITICS',
    TERMSOFUSE: 'auth/TERMS_OF_USE',
    TERMS_OF_USE_ACCEPTED: 'auth/TERMS_OF_USE_ACCEPTED',
    STATUS: 'auth/STATUS',
    ENTERPRISE: 'auth/ENTERPRISE',
    AUTH_QUESTION_LIST: 'auth/AUTH_QUESTION_LIST',
    AUTH_USER_ANSWER_UPDATE: 'auth/AUTH_USER_ANSWER_UPDATE',
    BRAND: 'auth/BRAND',
    ROLE: 'auth/ROLE',
    ROLE_FAMILY: 'auth/ROLE_FAMILY',
    ROLE_GROUP: 'auth/ROLE_GROUP',
    POSITION: 'auth/POSITION',
    REGIONAL: 'auth/REGIONAL',
    SUBAREA: 'auth/SUBAREA',
    BOARD: 'auth/BOARD',
    EXECUTIVE_BOARD: 'auth/EXECUTIVE_BOARD',
    MANAGING: 'auth/MANAGING',
    COST_CENTER: 'auth/COST_CENTER',
    EXTERNAL_ENTERPRISE: 'auth/EXTERNAL_ENTERPRISE',
    RESET_AZURE_SAML: 'auth/RESET_AZURE_SAML',
    RESET: 'auth/RESET',
};



// INITIAL STATE
const modules = localStorage.getItem('auth.state.modules');
const jwt = localStorage.getItem('auth.state.jwt');
const refresh_token = localStorage.getItem('auth.state.refresh_token');
const authenticated = localStorage.getItem('auth.state.authenticated');
const user = localStorage.getItem('auth.state.user');
const passwordSuccess = 'error';
const success = false;
const loading = false;
const showLogin = false;

const initialState = {
    modules: modules && modules.length > 0 ? JSON.parse(modules) : {},
    jwt: jwt || '',
    refresh_token: refresh_token || '',
    authenticated: authenticated && authenticated.length > 0 ? JSON.parse(authenticated) : false,
    user: user && user.length > 0 ? JSON.parse(user) : false,
    passwordSuccess,
    success,
    completeDataStatus: '',
    questionsStatus: '',
    loginError: '',
    status: '',
    code: '',
    need_activation_code: '',
    loading,
    showLogin,
    azureSaml: {},
    privacyPolitics: {},
    termsOfUse: {},
    enterprise: {},
    recoverPassword: {},
    firstAccess: {},
    firstAccessStatus: '',
    authQuestionList: {},
    authUserAnswer: {},
    brand: {},
    role: {},
    roleFamily: {},
    roleGroup: {},
    position: {},
    regional: {},
    subarea: {},
    board: {},
    executiveBoard: {},
    managing: {},
    costCenter: {},
    externalEnterprise: {},
    preferencesStatus: '',
};


// REDUCER

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Types.SHOWLOGIN:
            return {
                ...state,
                showLogin: !state.showLogin
            }
        case Types.PRIVACYPOLITICS:
            return {
                ...state,
                privacyPolitics: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.TERMSOFUSE:
            return {
                ...state,
                termsOfUse: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.AZURE_SAML:
            return {
                ...state,
                azureSaml: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.AUTH_QUESTION_LIST:
            return {
                ...state,
                authQuestionList: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.AUTH_USER_ANSWER_UPDATE:
            return {
                ...state,
                authUserAnswer: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                },
                questionsStatus: action.payload.status,
            }
        case Types.LOADING:
            return {
                ...state,
                loading: action.payload.loading,
            }
        case Types.ERROR:
            return {
                ...state,
                loginError: action.payload.loginError,
                errorMessage: action.payload.errorMessage,
                loading: action.payload.loading,
                status: "error"
            }
        case Types.STATUS:
            return {
                ...state,
                status: action.payload.status,
            }

        case Types.RECOVER_PASSWORD:
            return {
                ...state,
                recoverPassword: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                },
                status: action.payload.status,
            }
        case Types.FIRST_ACCESS:
            return {
                ...state,
                firstAccess: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                },
                firstAccessStatus: action.payload.status,
            }
        case Types.GET_CODE:
            return {
                ...state,
                code: action.payload.code,
                status: "success"
            }
        case Types.ENTERPRISE:
            return {
                ...state,
                enterprise: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.BRAND:
            return {
                ...state,
                brand: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.ROLE:
            return {
                ...state,
                role: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.ROLE_FAMILY:
            return {
                ...state,
                roleFamily: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.ROLE_GROUP:
            return {
                ...state,
                roleGroup: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.POSITION:
            return {
                ...state,
                position: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.REGIONAL:
            return {
                ...state,
                regional: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.SUBAREA:
            return {
                ...state,
                subarea: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.BOARD:
            return {
                ...state,
                board: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.EXECUTIVE_BOARD:
            return {
                ...state,
                executiveBoard: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.MANAGING:
            return {
                ...state,
                managing: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.COST_CENTER:
            return {
                ...state,
                costCenter: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.EXTERNAL_ENTERPRISE:
            return {
                ...state,
                externalEnterprise: {
                    data: action.payload.data,
                    load: action.payload.load,
                    error: action.payload.error,
                    messages: action.payload.messages,
                }
            }
        case Types.CREATE_ACCOUNT:
            return {
                ...state,
                need_activation_code: action.payload.need_activation_code,
                status: "success"
            }
        case Types.LOGIN:
            localStorage.setItem('auth.state.modules', JSON.stringify(action.payload.modules));
            localStorage.setItem('auth.state.user', JSON.stringify(action.payload.user));
            localStorage.setItem('auth.state.authenticated', 'true');

            return {
                ...state,
                modules: action.payload.modules,
                user: { ...action.payload.user, ...action.payload.server_date },
                authenticated: true,
                loginError: action.payload.loginError,
                status: "success"
            }
        case Types.UPDATE_POINTS:
            state.user = { ...state.user, ...action.payload.user }
            localStorage.setItem('auth.state.user', JSON.stringify(state.user));
            return {
                ...state,
                user: state.user
            }

        case Types.COMPLETE_DATA:
            state.user = { ...state.user, ...action.payload.user }
            localStorage.setItem('auth.state.user', JSON.stringify(state.user));

            return {
                ...state,
                completeDataStatus: action.payload.status,
                user: state.user,
            }

        case Types.UPDATE_PROFILE:
            state.user = { ...state.user, ...action.payload.user }
            localStorage.setItem('auth.state.user', JSON.stringify(state.user));
            return {
                ...state,
                user: state.user,
            }
        case Types.TERMS_OF_USE_ACCEPTED:
            state.user.accept_terms = 'Y';
            localStorage.setItem('auth.state.user', JSON.stringify(state.user));
            return {
                ...state,
            }

        case Types.EXPIRED_PASSWORD_UPDATE:
            if (action.payload.type === 'temporary') {
                state.user.is_temporary_password = 'N';
                localStorage.setItem('auth.state.user', JSON.stringify(state.user));
            } else {
                if (action.payload.type === 'expired') {
                    state.user.is_expired_password = 'N';
                    localStorage.setItem('auth.state.user', JSON.stringify(state.user));
                }
            }
            return {
                ...state,
                passwordSuccess: action.payload.passwordSuccess,
                status: action.payload.status,
            }
        case Types.PHOTO_UPDATE:
            state.user.image = action.payload.image;
            localStorage.setItem('auth.state.user', JSON.stringify(state.user));
            return {
                ...state,
                success: true,
            }
        case Types.PREFERENCES_UPDATE:
            state.user.enable_push = action.payload.enable_push;
            state.user.enable_sfx = action.payload.enable_sfx;
            state.user.enable_news = action.payload.enable_news;
            state.user.enable_notifications = action.payload.enable_notifications;
            state.user.enable_music = action.payload.enable_music;
            state.user.layout_theme = action.payload.layout_theme;
            localStorage.setItem('auth.state.user', JSON.stringify(state.user));

            return {
                ...state,
                preferencesStatus: action.payload.status,
            }
        case Types.JWT:
            localStorage.setItem('auth.state.jwt', action.payload.jwt);
            localStorage.setItem('auth.state.refresh_token', action.payload.refresh_token);
            return {
                ...state,
                jwt: action.payload.jwt,
                refresh_token: action.payload.refresh_token,
                authenticated: true,
            }
        case Types.AZURE_SAML_LOGIN:
            delete action.payload.user.cpf;
            localStorage.setItem('auth.state.modules', JSON.stringify(action.payload.modules));
            localStorage.setItem('auth.state.user', JSON.stringify(action.payload.user));
            localStorage.setItem('auth.state.authenticated', 'true');

            return {
                ...state,
                jwt: action.payload.jwt,
                refresh_token: action.payload.refresh_token,
                authenticated: true,
                modules: action.payload.modules,
                user: action.payload.user,
                status: "success"
            }
        case Types.LOGOUT:
            localStorage.clear();
            return {
                ...state,
                modules: {},
                user: {},
                authenticated: false,
                loading: false
            }
        case Types.RESET_AZURE_SAML:
            return {
                ...state,
                azureSaml: {},
            }
        case Types.RESET_PROFILE_UPDATE_ERROR:
            return {
                ...state,
                profileError: {},
            }
        case Types.RESET:
            return {
                ...state,
                authQuestionList: {},
            }
        default:
            return state
    }
}


// ACTIONS

function isLoading(params) {
    return dispatch => {
        dispatch({
            type: Types.LOADING,
            payload: {
                loading: params
            }
        });
    }
}

function getPrivacyPolitics(params) {
    return dispatch => {
        return userService.getPrivacyPolitics(params).then(data => {
            dispatch({
                type: Types.PRIVACYPOLITICS,
                payload: {
                    data: data.privacy_statement,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.PRIVACYPOLITICS,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getTermsOfUse(params) {
    return dispatch => {
        return userService.getPrivacyPolitics(params).then(data => {
            dispatch({
                type: Types.TERMSOFUSE,
                payload: {
                    data: data.terms_of_use,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.TERMSOFUSE,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            })
        })
    }
}

function login(params) {
    let dataLogin = { login: '' };
    let passwordData;
    return dispatch => {
        return userService.jwt(params).then(data => {
            dataLogin.login = data[0].token;
            passwordData = data[0];
            dispatch({
                type: Types.JWT,
                payload: {
                    jwt: data[0].token,
                    refresh_token: data[0].refresh_token
                }
            });

            userService.login({ login: dataLogin.login, activation_code: params.activation_code }).then(data => {
                loginEvent({ data })
                if (params.auth_provider === 'AZUREAD') {
                    localStorage.setItem('auth.state.azure', true);
                }

                console.log(data)

                dispatch({
                    type: Types.LOGIN,
                    payload: {
                        modules: data.modules,
                        user: {
                            server_date: data.server_date,
                            is_temporary_password: passwordData.is_temporary_password,
                            is_expired_password: passwordData.expired_password,
                            ...data.user
                        }
                    }
                });
            }).catch(err => {
                dispatch({
                    type: Types.ERROR,
                    payload: {
                        loginError: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente',
                        errorMessage: err && err.messages ? err.messages[0] : 'Erro de conexão, tente novamente',
                        loading: false,
                    }
                });
                // console.log(err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente');
            })
        }).catch(err => {
            dispatch({
                type: Types.ERROR,
                payload: {
                    loginError: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente',
                    errorMessage: err && err.messages ? err.messages[0] : 'Erro de conexão, tente novamente',
                    loading: false,
                }
            });
        });
    }
}

function loginError(params) {
    return dispatch => {
        dispatch({
            type: Types.ERROR,
            payload: {
                loginError: params,
                errorMessage: params,
                loading: false,
            }
        });
    }
}

function getAzureSaml(params) {
    return dispatch => {
        return userService.getAzureSaml(params).then(data => {
            dispatch({
                type: Types.AZURE_SAML,
                payload: {
                    data: data.redirect_url,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.AZURE_SAML,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getAzureSamlLogin(params) {
    localStorage.setItem('auth.state.jwt', params.login);
    localStorage.setItem('auth.state.refresh_token', params.refresh_token);

    return dispatch => {
        return userService.login({ login: params.login }).then(data => {
            dispatch({
                type: Types.AZURE_SAML_LOGIN,
                payload: {
                    jwt: params.login,
                    refresh_token: params.refresh_token,
                    modules: data.modules,
                    user: {
                        server_date: data.server_date,
                        is_temporary_password: params.is_temporary_password,
                        is_expired_password: params.expired_password,
                        ...data.user
                    }
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.ERROR,
                payload: {
                    loginError: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente',
                    errorMessage: err && err.messages ? err.messages[0] : 'Erro de conexão, tente novamente',
                    loading: false,
                }
            });
        })
    }
}

function getEnterprise(params) {
    return dispatch => {
        return userService.getEnterprise(params).then(data => {
            dispatch({
                type: Types.ENTERPRISE,
                payload: {
                    data: data.enterprise,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.ENTERPRISE,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getBrandList(params) {
    return dispatch => {
        return userService.getBrandList(params).then(data => {
            dispatch({
                type: Types.BRAND,
                payload: {
                    data: data.brand,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.BRAND,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getRoleList(params) {
    return dispatch => {
        return userService.getRoleList(params).then(data => {
            dispatch({
                type: Types.ROLE,
                payload: {
                    data: data.roles,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.ROLE,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getRoleFamilyList(params) {
    return dispatch => {
        return userService.getRoleFamilyList(params).then(data => {
            dispatch({
                type: Types.ROLE_FAMILY,
                payload: {
                    data: data.families,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.ROLE_FAMILY,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getRoleGroupList(params) {
    return dispatch => {
        return userService.getRoleGroupList(params).then(data => {
            dispatch({
                type: Types.ROLE_GROUP,
                payload: {
                    data: data.role_group,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.ROLE_GROUP,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getPositionList(params) {
    return dispatch => {
        return userService.getPositionList(params).then(data => {
            dispatch({
                type: Types.POSITION,
                payload: {
                    data: data.position,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.POSITION,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getRegionalList(params) {
    return dispatch => {
        return userService.getRegionalList(params).then(data => {
            dispatch({
                type: Types.REGIONAL,
                payload: {
                    data: data.regional,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.REGIONAL,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getSubareaList(params) {
    return dispatch => {
        return userService.getSubareaList(params).then(data => {
            dispatch({
                type: Types.SUBAREA,
                payload: {
                    data: data.sub_areas,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.SUBAREA,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getBoardList(params) {
    return dispatch => {
        return userService.getBoardList(params).then(data => {
            dispatch({
                type: Types.BOARD,
                payload: {
                    data: data.boards,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.BOARD,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getExecutiveBoardList(params) {
    return dispatch => {
        return userService.getExecutiveBoardList(params).then(data => {
            dispatch({
                type: Types.EXECUTIVE_BOARD,
                payload: {
                    data: data.executive_boards,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.EXECUTIVE_BOARD,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getManagingList(params) {
    return dispatch => {
        return userService.getManagingList(params).then(data => {
            dispatch({
                type: Types.MANAGING,
                payload: {
                    data: data.managements,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.MANAGING,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getCostCenterList(params) {
    return dispatch => {
        return userService.getCostCenterList(params).then(data => {
            dispatch({
                type: Types.COST_CENTER,
                payload: {
                    data: data.cost_centers,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.COST_CENTER,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function getExternalEnterpriseList(params) {
    return dispatch => {
        return userService.getExternalEnterpriseList(params).then(data => {
            dispatch({
                type: Types.EXTERNAL_ENTERPRISE,
                payload: {
                    data: data.enterprise,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.EXTERNAL_ENTERPRISE,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function updatePoints(params) {
    return dispatch => {
        dispatch({
            type: Types.UPDATE_POINTS,
            payload: {
                user: {
                    qi_credit: params.qi_credit,
                    total_points: params.total_points,
                }
            }
        });
    }
}

function completeData(params) {
    return dispatch => {
        return userService.updateProfile(params).then(data => {
            dispatch({
                type: Types.COMPLETE_DATA,
                payload: {
                    id_user: data.id_user,
                    user: {
                        phone_ddi: params.phone_ddi,
                        phone_number: params.phone_number,
                        email: params.email,
                        birth_date: params.birth_date,
                        country: params.country,
                        state: params.state,
                        city: params.city,
                        information: {
                            mark_id: params.mark_id,
                            role_id: params.role_id,
                            role_family_id: params.role_family_id,
                            role_group_id: params.role_group_id,
                            position_id: params.position_id,
                            regional_id: params.regional_id,
                            sub_area_id: params.sub_area_id,
                            board_id: params.board_id,
                            executive_board_id: params.executive_board_id,
                            management_id: params.management_id,
                            center_id: params.cost_center_id,
                            external_enterprise_id: params.external_enterprise_id
                        }
                    },
                    status: 'success',
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.COMPLETE_DATA,
                payload: {
                    status: 'error',
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function updateProfile(params) {
    return dispatch => {
        dispatch({
            type: Types.UPDATE_PROFILE,
            payload: {
                user: {
                    fname: params.fname,
                    lname: params.lname,
                    birth_date: params.birth_date,
                    country: params.country,
                    gender: params.gender,
                    information: {
                        mark_id: params.mark_id,
                        role_id: params.role_id,
                        role_family_id: params.role_family_id,
                        role_group_id: params.role_group_id,
                        position_id: params.position_id,
                        regional_id: params.regional_id,
                        sub_area_id: params.sub_area_id,
                        board_id: params.board_id,
                        executive_board_id: params.executive_board_id,
                        management_id: params.management_id,
                        center_id: params.cost_center_id,
                        external_enterprise_id: params.external_enterprise_id
                    }
                }
            }
        });
    }
}

function updateDataRedeem(params) {
    return dispatch => {
        dispatch({
            type: Types.UPDATE_PROFILE,
            payload: {
                user: {
                    birth_date: params.birth_date,
                    cpf: params.cpf,
                    confirmed_redeem_data: "Y"
                }
            }
        });
    }
}

function updateMainAddress(params) {
    return dispatch => {
        dispatch({
            type: Types.UPDATE_PROFILE,
            payload: {
                user: {
                    has_main_address: "Y"
                }
            }
        });
    }
}

function updateTermsOfUse(params) {
    return dispatch => {
        return userService.updateTermsOfUse(params).then(data => {
            dispatch({
                type: Types.TERMS_OF_USE_ACCEPTED,
                payload: {
                    message: "success",
                    status: "success"
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.TERMS_OF_USE_ACCEPTED,
                payload: {
                    message: err && err.localized_messages ? err.localized_messages[0] : 'error',
                    status: "error"
                }
            })
        })
    }
}

function updateExpiredPassword(params) {
    return dispatch => {
        return userService.updateExpiredPassword(params).then(data => {
            dispatch({
                type: Types.EXPIRED_PASSWORD_UPDATE,
                payload: {
                    passwordSuccess: data.update,
                    status: "expired success",
                    type: params.type
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.EXPIRED_PASSWORD_UPDATE,
                payload: {
                    passwordSuccess: err && err.localized_messages ? err.localized_messages[0] : 'error',
                    status: "error"
                }
            })
        })
    }
}

function updatePhoto(params) {
    return dispatch => {
        return userService.updatePhoto(params).then(data => {
            dispatch({
                type: Types.PHOTO_UPDATE,
                payload: {
                    image: data.image,
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.PHOTO_UPDATE,
                payload: {
                    image: err && err.localized_messages ? 'info ' + err.localized_messages[0] : 'error',
                }
            })
        })
    }
}

function updatePreferences(params) {
    return dispatch => {
        return userService.updatePreferences(params).then(data => {
            dispatch({
                type: Types.PREFERENCES_UPDATE,
                payload: {
                    id_user: data.preferences.id_user,
                    enable_push: data.preferences.enable_push,
                    enable_sfx: data.preferences.enable_sfx,
                    enable_news: data.preferences.enable_news,
                    enable_notifications: data.preferences.enable_notifications,
                    enable_music: data.preferences.enable_music,
                    layout_theme: data.preferences.layout_theme,
                    status: 'success'
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.PREFERENCES_UPDATE,
                payload: {
                    status: 'error'
                }
            })
            console.log(err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente');
        })
    }
}

function logout() {
    return dispatch => {
        dispatch({
            type: Types.LOGOUT
        });
    }
}

// login({login: params.email, auth_password: params.auth_password, auth_provider: 'QRANIO'})
function signup(params) {
    return dispatch => {
        return userService.signup(params).then(data => {
            dispatch({
                type: Types.CREATE_ACCOUNT,
                payload: {
                    need_activation_code: data.need_activation_code
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.ERROR,
                payload: {
                    loginError: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente',
                }
            });
        })
    }
}

function signupLogin(params) {
    return dispatch => {
        return userService.signupLogin(params).then(data => {
            dispatch({
                type: Types.CREATE_ACCOUNT,
                payload: {
                    need_activation_code: data.need_activation_code
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.ERROR,
                payload: {
                    loginError: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente',
                }
            });
        })
    }
}

function setRecoverPasswordEmail(params) {
    return dispatch => {
        return userService.setRecoverPassword(params).then(data => {
            dispatch({
                type: Types.RECOVER_PASSWORD,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: '',
                    status: 'success',
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.RECOVER_PASSWORD,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente',
                    status: 'error',
                }
            });
        })
    }
}

function setRecoverPasswordCPF(params) {
    if (params.cpf) {
        params.cpf = removeMask(params.cpf);
        params.cpf = params.cpf.toString();
    }

    return dispatch => {
        return userService.setFirstAccess(params).then(data => {
            dispatch({
                type: Types.RECOVER_PASSWORD,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: '',
                    status: 'success',
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.RECOVER_PASSWORD,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente',
                    status: 'error',
                }
            });
        })
    }
}

function setFirstAccess(params) {
    if (params.cpf) {
        params.cpf = removeMask(params.cpf);
        params.cpf = params.cpf.toString();
    }

    return dispatch => {
        return userService.setFirstAccess(params).then(data => {
            dispatch({
                type: Types.FIRST_ACCESS,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: '',
                    status: 'success',
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.FIRST_ACCESS,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente',
                    status: 'error',
                }
            });
        })
    }
}

function getAuthQuestionList(params) {
    return dispatch => {
        return userService.getAuthQuestionList(params).then(data => {
            dispatch({
                type: Types.AUTH_QUESTION_LIST,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: ''
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.AUTH_QUESTION_LIST,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente'
                }
            });
        })
    }
}

function setAuthUserAnswerUpdate(params) {
    return dispatch => {
        return userService.setAuthUserAnswerUpdate(params).then(data => {
            dispatch({
                type: Types.AUTH_USER_ANSWER_UPDATE,
                payload: {
                    data: data,
                    load: 'finished',
                    error: false,
                    messages: '',
                    status: 'success',
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.AUTH_USER_ANSWER_UPDATE,
                payload: {
                    data: [],
                    load: 'finished',
                    error: true,
                    messages: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente',
                    status: 'error',
                }
            });
        })
    }
}

function getCode(params) {
    return dispatch => {
        return userService.getCode(params).then(data => {
            dispatch({
                type: Types.GET_CODE,
                payload: {
                    code: data.code,
                }
            });
        }).catch(err => {
            dispatch({
                type: Types.ERROR,
                payload: {
                    loginError: err && err.localized_messages ? err.localized_messages[0] : 'Erro de conexão, tente novamente',
                }
            });
        })
    }
}

function removeMask(cpf) {
    return cpf
        .replace(/\./, '')
        .replace(/\./, '')
        .replace(/-/, '')

}

function showSmallscreenLogin() {
    return dispatch => {
        dispatch({
            type: Types.SHOWLOGIN
        });
    }
}

function resetAzureSaml() {
    return dispatch => {
        dispatch({
            type: Types.RESET_AZURE_SAML
        });
    }
}
function resetProfileUpdateError() {
    return dispatch => {
        dispatch({
            type: Types.RESET_PROFILE_UPDATE_ERROR
        });
    }
}

function reset() {
    return dispatch => {
        dispatch({
            type: Types.RESET
        });
    }
}

export const actions = {
    showSmallscreenLogin,
    getTermsOfUse,
    getPrivacyPolitics,
    getEnterprise,
    getBrandList,
    getRoleList,
    getRoleFamilyList,
    getRoleGroupList,
    getPositionList,
    getRegionalList,
    getSubareaList,
    getBoardList,
    getExecutiveBoardList,
    getManagingList,
    getCostCenterList,
    getExternalEnterpriseList,
    updateTermsOfUse,
    getAzureSaml,
    getAzureSamlLogin,
    isLoading,
    loginError,
    login,
    setRecoverPasswordEmail,
    setRecoverPasswordCPF,
    getCode,
    logout,
    reset,
    completeData,
    updatePoints,
    updateProfile,
    updateDataRedeem,
    updateMainAddress,
    updateExpiredPassword,
    signup,
    signupLogin,
    updatePhoto,
    updatePreferences,
    setFirstAccess,
    getAuthQuestionList,
    setAuthUserAnswerUpdate,
    resetAzureSaml,
    resetProfileUpdateError
}
