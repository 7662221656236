import React, { useEffect, useState } from 'react';
import config from "../../config";
import intl from 'react-intl-universal';
import useReactRouter from 'use-react-router';
import { PrizesService } from '../../services/prizes.service';
import { useSelector, useDispatch } from 'react-redux';
import { actions as PrizesActions } from '../../redux/ducks/prizes';
import {
    Grid, useMediaQuery, AppBar, Select, MenuItem,
    Toolbar, IconButton, Typography, Container, InputBase
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { goToTopAnimation } from '../../utils/utils';
import { QrMenu } from '../../components/QrMenu';
import QrContent from '../../components/QrContent';
import PrizeItem from './components/PrizeItem';
import EmptyContent from '../../components/EmptyContent';
import ErrorContent from '../../components/ErrorContent';
import { LoadingPrizeList } from '../../components/Loading';
import { Icon } from '../../components/Images/Images';

export default function PrizesList(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const list = useSelector(state => state.prizes.list);
    const loading = useSelector(state => state.prizes.loading);
    const [didMount, setDidMount] = useState(false);
    const [page, setPage] = useState(1);
    const [filteredList, setFilteredList] = useState([]);
    const [order, setOrder] = useState(5);
    const [search, setSearch] = useState('');
    const [searchOpen, setSearchOpen] = useState(false);
    const isScreenUpMd = useMediaQuery(theme => theme.breakpoints.up('md'));
    let bottomMenuItem = config.layout.menu.nav_bottom_order.includes('MENU_PRIZES');
    let limit = 12

    useEffect(() => {
        setDidMount(true);

        if (didMount) {
            dispatch(PrizesActions.getList({
                page: 1,
                limit: limit,
                order: order
            }));
        }

        return () => {
            dispatch(PrizesActions.reset());
            setDidMount(false);
        }
    }, [didMount, dispatch, order, limit]);

    useEffect(() => {
        setFilteredList([]);

        async function fetchDataFiltered() {
            let searchList = await PrizesService.getList({
                page: 1,
                limit: limit,
                order: order,
                prize_search: search
            });
            setFilteredList(searchList);
        }

        const timer = setTimeout(() => {
            if (search !== "") {
                fetchDataFiltered()
            }
        }, 1000);

        return () => clearTimeout(timer);
    }, [search, order, limit]);

    const fetchData = async (pg) => {
        if (pg !== page) {
            dispatch(PrizesActions.getList({
                limit: limit,
                page: pg,
                order: order
            }));

            dispatch(PrizesActions.setLoading({
                loading: true
            }));
        }
    }

    const QrAppBar = () => {
        return (
            <AppBar className={`appbar appbar-${bottomMenuItem || isScreenUpMd ? "main" : "default"}`} position={`${bottomMenuItem || isScreenUpMd ? "static" : "sticky"}`} color="primary">
                <Toolbar>
                    {
                        !bottomMenuItem && !isScreenUpMd ?
                            <IconButton
                                color="inherit"
                                aria-label="back"
                                edge="start"
                                onClick={() => history.goBack()}
                            >
                                <Icon ic="back" />
                            </IconButton>
                            :
                            null
                    }
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {intl.get('PRIZES_TITLE')}
                        </Typography>
                        {
                            bottomMenuItem || isScreenUpMd ?
                                <div className="w-100 d-flex overflow-hidden">
                                    <Typography variant="body2" noWrap>
                                        {intl.get("PRIZES_SUBTITLE")}
                                    </Typography>
                                </div>
                                : null
                        }
                    </div>
                    {
                        searchOpen ?
                            <div className="appbar-search">
                                <InputBase
                                    className="appbar-search-bar"
                                    fullWidth
                                    placeholder={intl.get("SEARCH_COMPONENT")}
                                    autoFocus
                                    color='primary'
                                    variant="filled-basic"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    inputProps={{ 'aria-label': 'search' }}
                                />
                                <IconButton onClick={() => { setSearch(''); setFilteredList([]); setSearchOpen(false) }} className="ml-2">
                                    <Icon ic="cancel" />
                                </IconButton>
                            </div>
                            :
                            <>
                                <IconButton onClick={() => setSearchOpen(!searchOpen)}>
                                    <Icon ic="search" />
                                </IconButton>
                                <IconButton onClick={() => history.push({
                                    pathname: `${props.match.path.replace(/./g, '')}/prizes/bookmarks`,
                                    state: {
                                        origin: "list"
                                    }
                                })}>
                                    <Icon ic="bookmark_filled" />
                                </IconButton>
                            </>
                    }
                </Toolbar>
            </AppBar>
        )
    }

    const FilteredList = () => {
        if (filteredList?.prizes?.length > 0) {
            return (
                filteredList.prizes.map((item, index) => {
                    return (
                        <Grid item key={index} xs={6} sm={4} md={3}>
                            <PrizeItem
                                item={item}
                                action={() => history.push({
                                    pathname: `${props.match.path.replace(/./g, '')}/prizes/${item.id_prize}`,
                                    state: { origin: 'list' }
                                })}
                            />
                        </Grid>
                    )
                })
            )
        } else if (filteredList?.prizes?.length === 0) {
            return (
                <EmptyContent icon={<Icon ic="search" className="icon-xxl" />} text={intl.get("NO_RESULTS_FOUND")} />
            );
        }

        return (
            <LoadingPrizeList limit={8} />
        )
    }

    const handleChange = (event) => {
        setOrder(event.target.value);
        setPage(1);
        goToTopAnimation();
    };

    return (
        <div id="qr-wrap">
            <QrMenu />
            <div id="qr-content">
                <QrContent id="prizes" hideSidebar>
                    {
                        config.layout.navbar ?
                            <QrAppBar />
                            : null
                    }
                    <div className={`filter-bar${bottomMenuItem || isScreenUpMd ? " __main" : ""}`}>
                        <Icon ic="sort" className="mr-2 mt-1" />
                        <Select
                            labelId="prize-order"
                            id="prize-order"
                            variant={config.layout.input_variant}
                            value={order}
                            onChange={handleChange}
                        >
                            <MenuItem value={5}>{intl.get('LABEL_MOST_RECENT')}</MenuItem>
                            <MenuItem value={6}>{intl.get('LABEL_MOST_REDEEM')}</MenuItem>
                            <MenuItem value={1}>{intl.get('LABEL_A_Z')}</MenuItem>
                            <MenuItem value={2}>{intl.get('LABEL_Z_A')}</MenuItem>
                            <MenuItem value={3}>{intl.get('LABEL_LOWEST_QIS')}</MenuItem>
                            <MenuItem value={4}>{intl.get('LABEL_HIGHEST_QIS')}</MenuItem>
                        </Select>
                    </div>
                    <Container maxWidth="lg" className="pt-3 pb-4">
                        {
                            list && list.load === "finished" ?
                                !list.error ?
                                    list.data?.prizes.length !== 0 ?
                                        search.length !== 0 ?
                                            <Grid container spacing={2} className="category-list __fluid">
                                                <FilteredList />
                                            </Grid>
                                            :
                                            <>
                                                {
                                                    !loading ?
                                                        <Grid container spacing={2}>
                                                            {
                                                                list.data.prizes.map((item, index) => {
                                                                    return (
                                                                        <Grid item key={index} xs={6} sm={4} md={3}>
                                                                            <PrizeItem
                                                                                item={item}
                                                                                action={() => history.push({
                                                                                    pathname: `${props.match.path.replace(/./g, '')}/prizes/${item.id_prize}`,
                                                                                    state: { origin: 'list' }
                                                                                })}
                                                                            />
                                                                        </Grid>
                                                                    )
                                                                })
                                                            }
                                                        </Grid>
                                                        :
                                                        <LoadingPrizeList limit={limit} />
                                                }
                                                <Grid container className="mt-2">
                                                    <Grid item xs={12}>
                                                        <Pagination
                                                            count={Math.ceil(parseInt(list.data.total) / limit)}
                                                            page={page}
                                                            onChange={(e, value) => { fetchData(value); setPage(value); goToTopAnimation(); }}
                                                            defaultPage={1}
                                                            color="primary"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </>
                                        :
                                        <EmptyContent icon={<Icon ic="gift_filled" className="icon-xxl" />} text={intl.get("EMPTY_PRIZES")} />
                                    :
                                    <ErrorContent />
                                :
                                <LoadingPrizeList limit={8} />
                        }
                    </Container>
                </QrContent>
            </div>
        </div>
    )
}
