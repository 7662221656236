import React from 'react';
import { Grid, Typography, Container, List, ListItem, ListItemAvatar, ListItemText, Avatar } from '@material-ui/core';
import { Icon, Play } from './Images/Images';

function SectionModule(props) {
    const { children, iconSectionColor, iconBgColor, iconType, icon, title, subtitle, className, action } = props;

    return (
        <Grid container className={`module-section ${className ? className : ""}`}>
            <Container maxWidth={false} className="module-header">
                {
                    title ?
                        <List className={`pt-0 pb-3`}>
                            <ListItem
                                className={`p-0 ${action ? "cursor-pointer" : ""}`}
                                onClick={action ? action : null}
                            >
                                <ListItemAvatar>
                                    <Avatar
                                        aria-label="modules"
                                        style={iconBgColor ? { backgroundColor: iconBgColor } : null}
                                        className={`module-icon ${iconSectionColor ? iconSectionColor : null}`}
                                    >
                                        {
                                            iconType === "icon" ?
                                                <Icon ic={icon} color="white" />
                                                : iconType === "image" ?
                                                    <img src={icon} alt={title} />
                                                    : iconType === "play" ?
                                                        <Play type={icon} />
                                                        : null
                                        }
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    disableTypography
                                    primary={
                                        <Typography variant="h4" component="h3" >
                                            {title}
                                        </Typography>
                                    }
                                    secondary={
                                        subtitle ?
                                            <Typography variant="body2" component="p">
                                                {subtitle}
                                            </Typography>
                                            :
                                            null
                                    }
                                />
                            </ListItem>
                        </List>
                        :
                        ""
                }
            </Container>
            <Grid item xs={12} className="module-content">
                {children}
            </Grid>
        </Grid >
    );
}

export default SectionModule;
