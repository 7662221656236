import React from 'react';
import intl from 'react-intl-universal';
import { useSelector } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import MenuMore from '../scenes/More';
import RedirectComponent from '../components/RedirectComponent';
import ScrollToTop from '../components/ScrollToTop';
import Feed from '../scenes/Feeds';
import Highlight from '../scenes/Home/highlight.detail';
import KnowMore from '../scenes/Home/knowmore.detail';
import Home from '../scenes/Home';
import Intro from '../scenes/Intro';
import QrLogin from '../scenes/Intro/qrlogin';
import Login from '../scenes/Intro/login';
import SignUp from '../scenes/Intro/signup';
import RecoverPassword from '../scenes/Intro/recover.password';
import AccessCode from '../scenes/Intro/access.code';
import AccessCodeAlternative from '../scenes/Intro/access.code.alternative';
import Avatar from '../scenes/Intro/avatar';
import ExpiredPassword from '../scenes/Intro/expired.password';
import FirstAccess from '../scenes/Intro/first.access';
import FirstAccessQuestions from '../scenes/Intro/first.access.questions';
import SecretQuestions from '../scenes/Intro/secret.questions';
import Logout from "../scenes/Intro/logout";
import AcceptTerms from '../scenes/Intro/terms';
import CompleteData from '../scenes/Intro/complete.data';
import Learn from '../scenes/Learn';
import Classroom from '../scenes/Learn/Classroom';
import ClassroomDetails from '../scenes/Learn/Classroom/details';
import ClassroomTicket from '../scenes/Learn/Classroom/ticket';
import Exam from '../scenes/Learn/Exam';
import Free from '../scenes/Learn/Free';
import Scorm from '../scenes/Learn/Scorm';
import ScormList from '../scenes/Learn/Scorm/scorm.list';
import ScormDetails from '../scenes/Learn/Scorm/details';
import Manager from '../scenes/ManagerPanel';
import MyHistory from '../scenes/MyHistory';
import Notifications from '../scenes/Notifications';
import Periodic from '../scenes/Learn/Periodic';
import Profile from '../scenes/Profile';
import ProfileTrail from '../scenes/Profile/list.trail';
import ProfileScorm from '../scenes/Profile/list.scorm';
import ProfileClassroom from '../scenes/Profile/list.classroom';
import ProfilePerformance from '../scenes/Profile/list.performance';
import Prizes from '../scenes/Prizes';
import Ranking from "../scenes/Ranking";
import CodeRedeem from '../scenes/CodeRedeem';
import MyRedeems from '../scenes/MyRedeems';
import Settings from '../scenes/Settings';
import Faq from '../scenes/Faq';
import Trail from '../scenes/Learn/Trail';
import TrailList from '../scenes/Learn/Trail/trail.list';
import TrailDetails from '../scenes/Learn/Trail/details';
import TrailStatistics from '../scenes/Learn/Trail/statistics';
import TrailStepList from '../scenes/Learn/Trail/step.list';
import TrailMaterials from '../scenes/Learn/Trail/materials';
import Library from '../scenes/Library';
import CertificateAuthentication from '../scenes/Public/certificate.authentication';
import AuthSaml from '../scenes/Intro/components/AuthSaml';
import AuthProjectSignin from '../scenes/Intro/components/AuthProjectSignin';
import TermsOfUse from '../scenes/Intro/public/terms';
import PrivacyPolicies from '../scenes/Intro/public/privacy';


var jsonMerger = require("json-merger");


const tryRequire = (path) => {
  try {
    return require(`../locales/${path}`)
  } catch (err) {
    return {}
  }
};

var result_pt_BR = jsonMerger.mergeObjects([require('../locales/pt-BR/pt-BR.json'), tryRequire(`pt-BR/projects/${process.env.REACT_APP_QR_PROJECT.toLowerCase()}.json`)]);
var result_en_US = jsonMerger.mergeObjects([require('../locales/en-US/en-US.json'), tryRequire(`en-US/projects/${process.env.REACT_APP_QR_PROJECT.toLowerCase()}.json`)]);
var result_es_ES = jsonMerger.mergeObjects([require('../locales/es-ES/es-ES.json'), tryRequire(`es-ES/projects/${process.env.REACT_APP_QR_PROJECT.toLowerCase()}.json`)]);


const locales = {
  'pt-BR': result_pt_BR,
  'en-US': result_en_US,
  'es-ES': result_es_ES
}

export const currentLocale = locales[navigator.language] ? navigator.language : 'pt-BR';

export default function Router() {
  intl.init({
    currentLocale,
    locales
  })

  const auth = useSelector(state => state.auth);
  const passwordExpired = auth.user.is_expired_password === "Y";
  const passwordTemporary = auth.user.is_temporary_password === "Y";
  const acceptedUseTerms = localStorage.getItem('auth.state.user') && JSON.parse(localStorage.getItem('auth.state.user')).accept_terms === 'Y';
  const userAuthenticated = auth.authenticated === true && Object.keys(auth.user).length !== 0;
  const userHaveQuestions = localStorage.getItem('auth.state.user') && JSON.parse(localStorage.getItem('auth.state.user')).has_questions === 'Y';

  //FUTURO PARA VERIFICAR PWA INSTALADO CHROME 84
  //     const status = document.getElementById('status');
  // const ul = document.getElementById('installedApps');

  // window.addEventListener('load', () => {
  //   // Check to see if the API is supported.
  //   console.log('Checando')

  //   if ('getInstalledRelatedApps' in navigator) {
  //     console.log('Checado')
  //     checkForRelatedApps();
  //   }
  // });

  // function checkForRelatedApps() {
  //     console.log('Acessado Novo')

  //   navigator.getInstalledRelatedApps().then((relatedApps) => {
  //     console.log('relatedApps', relatedApps)

  //     relatedApps.forEach((app) => {
  //       console.log(app)
  //     });
  //   });
  // }

  // async function getApps() {
  //     const relatedApps = await navigator.getInstalledRelatedApps();
  //     console.log(relatedApps)
  //     // relatedApps.forEach((app) => {
  //     // console.log(app.id, app.platform, app.url);
  //     // });
  // }
  // getApps()

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props =>
        userAuthenticated && !passwordTemporary && !passwordExpired && acceptedUseTerms ? (
          <Component {...props} />
        ) :
          !userAuthenticated ?
            (
              <Redirect
                to={{
                  pathname: "/",
                  state: { from: props.location }
                }}
              />
            ) :
            passwordTemporary ?
              (
                <Redirect
                  to={{
                    pathname: "/temporary-password",
                    state: { from: props.location }
                  }}
                />
              ) : passwordExpired ?
                (
                  <Redirect
                    to={{
                      pathname: "/expired-password",
                      state: { from: props.location }
                    }}
                  />
                ) : !acceptedUseTerms ?
                  (
                    <Redirect
                      to={{
                        pathname: "/accept-terms",
                        state: { from: props.location }
                      }}
                    />
                  )
                  : userHaveQuestions !== "undefined" && userHaveQuestions === false ?
                    (
                      <Redirect
                        to={{
                          pathname: "/first-access-questions",
                          state: { from: props.location }
                        }}
                      />
                    )
                    : (
                      <Redirect
                        to={{
                          pathname: "/",
                          state: { from: props.location }
                        }}
                      />
                    )
      }
    />
  );

  return (
    <BrowserRouter onUpdate={() => window.scrollTo(0, 0)}>
      <LastLocationProvider>
        <ScrollToTop />
        <Switch>
          {/* ROTAS PRIVADAS */}
          <PrivateRoute exact path="/home" component={Home} />
          <PrivateRoute exact path="/news/:id" component={KnowMore} />
          <PrivateRoute exact path="/news/highlight/:id" component={Highlight} />
          <PrivateRoute exact path="/feeds" component={Feed} />
          <PrivateRoute exact path="/more" component={MenuMore} />
          <PrivateRoute exact path="/learn" component={Learn} />
          <PrivateRoute exact path="/ranking" component={Ranking} />
          <PrivateRoute path="/profile/:id" exact={true} component={Profile} />
          <PrivateRoute path="/profile/:id/trail" exact={true} component={ProfileTrail} />
          <PrivateRoute path="/profile/:id/scorm" exact={true} component={ProfileScorm} />
          <PrivateRoute path="/profile/:id/classroom" exact={true} component={ProfileClassroom} />
          <PrivateRoute path="/profile/:id/performance" exact={true} component={ProfilePerformance} />
          <PrivateRoute path="/prizes" component={Prizes} />
          <PrivateRoute path="/manager" component={Manager} />
          <PrivateRoute path="/my-history" exact={true} component={MyHistory} />
          <PrivateRoute path="/scorm" component={Scorm} />
          <PrivateRoute path="/scorm/:id/list" component={ScormList} />
          <PrivateRoute path="/scorm/:id/details" component={ScormDetails} />
          <PrivateRoute path="/classroom/qrcode" component={RedirectComponent} />
          <PrivateRoute path="/classroom" component={Classroom} />
          <PrivateRoute path="/classroom/:id" component={ClassroomDetails} />
          <PrivateRoute path="/classroom/:id/ticket/:id" component={ClassroomTicket} />
          <PrivateRoute path="/library" component={Library} />
          <PrivateRoute path="/free" component={Free} />
          <PrivateRoute path="/trail" component={Trail} />
          <PrivateRoute path="/trail/:id/list" component={TrailList} />
          <PrivateRoute path="/trail/:id" component={TrailDetails} />
          <PrivateRoute path="/trail/:id/statistics" component={TrailStatistics} />
          <PrivateRoute path="/trail/:id/:id" component={TrailStepList} />
          <PrivateRoute path="/trail/:id/:id/materials" component={TrailMaterials} />
          <PrivateRoute path="/exam" component={Exam} />
          <PrivateRoute path="/periodic" component={Periodic} />
          <PrivateRoute path="/code-redeem" component={CodeRedeem} />
          <PrivateRoute path="/notifications" component={Notifications} />
          <PrivateRoute path="/my-redeems" component={MyRedeems} />
          <PrivateRoute path="/settings" component={Settings} />
          <PrivateRoute path="/faq" component={Faq} />

          {/* ROTAS PRIVADAS POREM COM INTROLAYOUT*/}
          <PrivateRoute path="/avatar" exact={true} component={Avatar} />

          {/* ROTAS PÚBLICAS */}
          <Route path="/" exact={true} component={Intro} /> {/* CAPTCHA DONE */}
          <Route path="/qrlogin" exact={true} component={QrLogin} /> {/* CAPTCHA DONE */}
          <Route path="/login" exact={true} component={Login} /> {/* CAPTCHA DONE */}
          <Route path="/signup" exact={true} component={SignUp} />  {/* CAPTCHA DONE */}
          <Route path="/access-code" exact={true} component={AccessCode} />  {/* CAPTCHA DONE */}
          <Route path="/access-code-alternative" exact={true} component={AccessCodeAlternative} /> {/* CAPTCHA DONE */}
          <Route path="/recover-password" exact={true} component={RecoverPassword} /> {/* CAPTCHA DONE */}
          <Route path="/expired-password" exact={true} component={ExpiredPassword} /> {/* CAPTCHA DONE */}
          <Route path="/temporary-password" exact={true} component={ExpiredPassword} /> {/* CAPTCHA DONE */}
          <Route path="/first-access" exact={true} component={FirstAccess} />  {/* CAPTCHA DONE */}
          <Route path="/first-access-questions" exact={true} component={FirstAccessQuestions} />  {/* CAPTCHA DONE */}
          <Route path="/secret-questions" exact={true} component={SecretQuestions} />  {/* CAPTCHA DONE */}
          <Route path="/complete-data" exact={true} component={CompleteData} /> {/* CAPTCHA DONE */}
          <Route path="/logout" exact={true} component={Logout} />
          <Route path="/accept-terms" exact={true} component={AcceptTerms} />
          <Route path="/terms-of-use" exact={true} component={TermsOfUse} />
          <Route path="/privacy-policies" exact={true} component={PrivacyPolicies} />
          <Route path="/auth/certificate/:typeCourse/:idUser/:idCourse/:idUserCourse" exact={true} component={CertificateAuthentication} />
          <Route path="/auth/azure/saml/callback/:token" exact={true} component={AuthSaml} />
          <Route path="/auth/project/signin/:token" exact={true} component={AuthProjectSignin} />
          <Route path="/auth/project/signin/course/:token" exact={true} component={AuthProjectSignin} />
        </Switch>
      </LastLocationProvider>
    </ BrowserRouter>
  );
}
