import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import config from '../../../config';
import useReactRouter from 'use-react-router';
import { actions as ExamActions } from '../../../redux/ducks/exam';
import {
    Typography, AppBar, Toolbar, IconButton, Container
} from '@material-ui/core';
import { QrMenu } from '../../../components/QrMenu';
import QrContent from '../../../components/QrContent';
import EmptyContent from '../../../components/EmptyContent';
import ErrorContent from '../../../components/ErrorContent';
import ExamDetailsIntro from './components/ExamDetailsIntro';
import ExamDetailsResult from './components/ExamDetailsResult';
import ExamDetailsWaiting from './components/ExamDetailsWaiting';
import { Icon } from '../../../components/Images/Images';
import { LoadingContent } from '../../../components/Loading';

export default function ExamDetails(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const [didMount, setDidMount] = useState(false);
    const details = useSelector(state => state.exam.details);
    const origin = props.location.state && props.location.state.origin ? props.location.state.origin : '';

    useEffect(() => {
        setDidMount(true);

        if (didMount) {
            dispatch(ExamActions.getDetails({
                id_exam: props.match.params.idExam,
            }));
        }

        return () => {
            dispatch(ExamActions.reset());
            setDidMount(false);
        }
    }, [didMount, dispatch, props.match]);

    function goBack() {
        let currentOrigin = "details";

        switch (origin) {
            case 'list':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/exam`,
                    state: {
                        origin: currentOrigin
                    }
                })
            case 'questions':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/exam`,
                    state: {
                        origin: currentOrigin
                    }
                })
            case 'feedback':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/exam`,
                    state: {
                        origin: currentOrigin
                    }
                })
            default:
                return history.goBack()
        }
    }

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-overlay" position="absolute">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="back"
                        edge="start"
                        onClick={() => goBack()}
                    >
                        <Icon ic="back" />
                    </IconButton>
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {details.data && details.data.name}
                        </Typography>
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

    return (
        <div id="qr-wrap">
            <QrMenu />
            <div id="qr-content">
                <QrContent id="exam-details" hideSidebar>
                    {
                        config.layout.navbar ?
                            <QrAppBar />
                            : null
                    }
                    {
                        details && details.load === "finished" ?
                            !details.error ?
                                details.data.length !== 0 ?
                                    details.data.done === "Y" ?
                                        details.data.result_available === "Y" ?
                                            <ExamDetailsResult
                                                item={details.data}
                                                {...props}
                                            />
                                            :
                                            <ExamDetailsWaiting
                                                item={details.data}
                                                {...props}
                                            />
                                        :
                                        <ExamDetailsIntro
                                            item={details.data}
                                            {...props}
                                        />
                                    :
                                    <Container maxWidth="md">
                                        <EmptyContent icon={<Icon ic="play_exam" className="icon-xxl" />} text={intl.get("EMPTY_EXAM")} />
                                    </Container>
                                :
                                <Container maxWidth="md">
                                    <ErrorContent />
                                </Container>
                            :
                            <Container maxWidth="md">
                                <LoadingContent />
                            </Container>
                    }
                </QrContent>
            </div>
        </div>
    )
}