import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import config from '../config';
import useReactRouter from 'use-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { actions as SurveyActions } from '../redux/ducks/survey';
import { getKey, numberMask } from '../utils/utils';
import {
    AppBar, Toolbar, Container, Typography, IconButton,
    Button, Divider, Grid, ListItem, ListItemText
} from '@material-ui/core';
import { QrMenu } from './QrMenu';
import QrContent from './QrContent';
import Quiz from './Game/Quiz';
import EmptyContent from './EmptyContent';
import ErrorContent from './ErrorContent';
import QrList from './QrList';
import { LoadingContent } from './Loading';
import { Icon, Points, Char, Ribbon } from './Images/Images';

export default function SurveyQuestions(props) {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const { history } = useReactRouter();
    const { question } = useSelector(state => state.survey);
    const data = props.location.state && props.location.state.data ? props.location.state.data : '';
    const idName = props.location.state && props.location.state.idName ? props.location.state.idName : '';
    const title = props.location.state && props.location.state.title ? props.location.state.title : '';
    const origin = props.location.state && props.location.state.origin ? props.location.state.origin : '';
    const [didMount, setDidMount] = useState(false);
    const [loadingQuestion, setLoadingQuestion] = useState(false);
    const key = getKey(data, idName);

    useEffect(() => {
        if (auth.authenticated === false) {
            history.replace('/');
        }
    }, [auth, history, data.has_survey, origin, question]);

    useEffect(() => {
        setDidMount(true);

        if (didMount) {
            if (origin === "trail") {
                dispatch(SurveyActions.getSurveyQuestion({
                    [key]: data[key]
                }));
            } else if (origin === "scorm") {
                dispatch(SurveyActions.getScormSurveyQuestion({
                    [key]: data[key]
                }));
            } else if (origin === "classroom") {
                dispatch(SurveyActions.getClassroomSurveyQuestion({
                    [key]: data[key]
                }));
            }
        }

        return () => {
            setDidMount(false);
            dispatch(SurveyActions.reset());
        }
    }, [didMount, dispatch, data, key, origin]);

    if (!data.has_survey || (data.has_survey && data.has_survey === 'N')) {
        return history.goBack()
    }

    const sendTo = async (answer, idQuestion) => {
        setLoadingQuestion(true);

        if (origin === "trail") {
            await dispatch(SurveyActions.getSurveyAnswer({
                [key]: data[key],
                id_question: idQuestion,
                answer: answer
            }));
        } else if (origin === "scorm") {
            await dispatch(SurveyActions.getScormSurveyAnswer({
                [key]: data[key],
                id_question: idQuestion,
                answer: answer
            }));
        } else if (origin === "classroom") {
            await dispatch(SurveyActions.getClassroomSurveyAnswer({
                [key]: data[key],
                id_question: idQuestion,
                answer: answer
            }));
        }

        setLoadingQuestion(false);
    }

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-transparent" position="absolute" color="primary">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="close"
                        edge="start"
                        onClick={() => history.goBack()}
                    >
                        <Icon ic="close" />
                    </IconButton>
                    <div className="w-100 overflow-hidden">
                        <Typography variant="h4" noWrap>
                            {intl.get("SURVEY_TITLE")}
                        </Typography>
                        <div className="w-100 d-flex overflow-hidden">
                            <Typography variant="body2" noWrap>
                                {title}
                            </Typography>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

    const Header = () => {
        return (
            config.learn.trail.vector_images && config.learn.trail.chars ?
                <Grid container id="survey-finished" justify="center" className="appbar-custom-content">
                    <Container maxWidth="md">
                        <Grid container justify="center" spacing={2}>
                            <Grid item xs={12} sm={10} lg={8} xl={10} align="center">
                                <Ribbon
                                    type="straight-filled"
                                    text={intl.get("SURVEY_FINISHED_TITLE")}
                                />
                            </Grid>
                            <Grid item xs={12} sm={10} lg={8} xl={10} align="center" className="result-char">
                                <Char type="survey-finished" />
                            </Grid>
                            <Grid item xs={12} sm={10} lg={8} xl={10} align="center">
                                <Typography variant="body1" component="p">
                                    {question.data.message}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
                :
                <Grid container id="survey-finished" justify="center" className="appbar-custom-content __no-vector">
                    <div className="__filter">
                        <Container maxWidth="md" className="pb-3 h-100">
                            <Grid container alignContent="flex-end" justify="center" spacing={1} className="h-100">
                                <Grid item xs={12} sm={10} lg={8} xl={10} align="center">
                                    <Typography variant="h2" component="h1">
                                        {intl.get("SURVEY_FINISHED_TITLE")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={10} lg={8} xl={10} align="center">
                                    <Typography variant="body1" component="p">
                                        {question.data.message}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Container>
                    </div>
                </Grid>
        )
    }

    const ListResult = () => {
        auth.user.qi_credit = question.data.user.qi_credit;
        auth.user.total_points = question.data.user.total_points;
        localStorage.setItem('auth.state.user', JSON.stringify(auth.user));

        return (
            <Container maxWidth="md" className="pb-3">
                <Grid container justify="center" spacing={2}>
                    <Grid item xs={12} sm={8} xl={10}>
                        <QrList>
                            <ListItem disableGutters>
                                <ListItemText
                                    disableTypography
                                    primary={
                                        <div>
                                            <Typography component="span" variant="body1">
                                                {intl.get("LABEL_XP")}
                                            </Typography>
                                            <Typography component="span" variant="body1"
                                                className={`${question.data.user.points_gained > 0 ? "text-success" : "text-lighter"} ml-2`}
                                            >
                                                {question.data.user.points_gained > 0 ? "+" : ""}
                                                {numberMask(question.data.user.points_gained)}
                                            </Typography>
                                        </div>
                                    }
                                    secondary={
                                        <div className="d-flex flex-row align-items-center">
                                            <Points type="xp" className="img-xs mr-1" />
                                            <Typography variant="body1" className="text-xp" component="span">
                                                {numberMask(question.data.user.total_points)}
                                            </Typography>
                                        </div>
                                    }
                                />
                            </ListItem>
                            {
                                config.layout.points.qis ?
                                    <>
                                        <Divider />
                                        <ListItem disableGutters>
                                            <ListItemText
                                                disableTypography
                                                primary={
                                                    <div>
                                                        <Typography component="span" variant="body1">
                                                            {intl.get("LABEL_QIS")}
                                                        </Typography>
                                                        <Typography component="span" variant="body1"
                                                            className={`${question.data.user.qi_gained > 0 ? "text-success" : "text-lighter"} ml-2`}
                                                        >
                                                            {question.data.user.qi_gained > 0 ? "+" : ""}
                                                            {numberMask(question.data.user.qi_gained)}
                                                        </Typography>
                                                    </div>
                                                }
                                                secondary={
                                                    <div className="d-flex flex-row align-items-center">
                                                        <Points type="qis" className="img-xs mr-1" />
                                                        <Typography variant="body1" className="text-qis" component="span">
                                                            {numberMask(question.data.user.qi_credit)}
                                                        </Typography>
                                                    </div>
                                                }
                                            />
                                        </ListItem>
                                    </>
                                    : null
                            }
                        </QrList>
                    </Grid>
                </Grid>
            </Container>
        )
    }

    return (
        <div id="qr-wrap">
            <QrMenu />
            <div id="qr-content">
                <QrContent id="survey" hideSidebar>
                    {
                        config.layout.navbar ?
                            <QrAppBar />
                            : null
                    }
                    {
                        !loadingQuestion && (question && question.load === "finished") ?
                            !question.error ?
                                question.data.length !== 0 ?
                                    question.data.finished === "Y" ?
                                        <div>
                                            {/* Header */}
                                            <Header />

                                            {/* List */}
                                            <ListResult />

                                            {/* Buttons */}
                                            <Container maxWidth="md" className="pb-4">
                                                <Grid container justify="center">
                                                    <Grid item xs={12} sm={8} lg={6}>
                                                        <div id="bottom-bar" className="relative-bar">
                                                            <Button id="btn_survey_finished_exit" variant="contained" className="btn-block" onClick={() => history.goBack()}>
                                                                <Icon ic="exit" className="mr-2" /> {intl.get("BTN_EXIT")}
                                                            </Button>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </Container>
                                        </div>
                                        :
                                        <Quiz
                                            module="survey"
                                            question={question.data}
                                            sendAnswer={sendTo}
                                        />
                                    :
                                    <Container maxWidth="md">
                                        <EmptyContent icon={<Icon ic="rating" className="icon-xxl" />} text={intl.get("EMPTY_SURVEY")} />
                                    </Container>
                                :
                                <Container maxWidth="md">
                                    <ErrorContent />
                                </Container>
                            :
                            <Container maxWidth="md">
                                <LoadingContent />
                            </Container>
                    }
                </QrContent>
            </div>
        </div>
    )
}