import React, { useState } from 'react';
import config from '../config';
import { useSelector } from 'react-redux';
import BounceLoader from 'react-spinners/BounceLoader';
import ContentLoader from 'react-content-loader';
import { Grid, Card, Divider, ListItem } from '@material-ui/core';
import SectionList from './SectionList';
import SectionCategoryGrid from './SectionCategoryGrid';

const project = config.app.name_project.toLowerCase();
let theme = require(`../assets/scss/projects/${project}/_export.scss`);
let colorLight = theme["black"];
let colorDark = theme["white"];
let primaryOpacity = 0.075;
let secondaryOpacity = 0.15;

export function LoadingBounce(props) {
    const { color, size } = props;

    return (
        <div className="m-3">
            <BounceLoader
                sizeUnit={"px"}
                size={size ? size : 32}
                color={color ? theme[color] : theme["primary"]}
                loading={true}
            />
        </div>
    )
}

export function LoadingContent(props) {
    const { color, size } = props;

    return (
        <div className="loading-content">
            <div className="m-3">
                <BounceLoader
                    sizeUnit={"px"}
                    size={size ? size : 32}
                    color={color ? theme[color] : theme["primary"]}
                    loading={true}
                />
            </div>
        </div>
    )
}

export function LoadingPeriodic(props) {
    const { color, size, type } = props;

    return (
        <div className={`loading-periodic-${type.toLowerCase()}`}>
            <div className="m-3">
                <BounceLoader
                    sizeUnit={"px"}
                    size={size ? size : 32}
                    color={color ? theme[color] : theme["primary"]}
                    loading={true}
                />
            </div>
        </div>
    )
}

export function LoadingMore(props) {
    const { color, size } = props;

    return (
        <div className="loading-more">
            <BounceLoader
                sizeUnit={"px"}
                size={size ? size : 32}
                color={color ? theme[color] : theme["primary"]}
                loading={true}
            />
        </div>
    )
}

export function LoadingListIcon1(props) {
    const auth = useSelector(state => state.auth);
    const [themeMode] = useState(auth.user.layout_theme ? auth.user.layout_theme : config.layout.theme);
    let primaryColor = themeMode === "DARK" ? colorDark : colorLight;
    let secondaryColor = themeMode === "DARK" ? colorDark : colorLight;

    return (
        <ContentLoader
            height={28}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            primaryOpacity={primaryOpacity}
            secondaryOpacity={secondaryOpacity}
            {...props}
        >
            <circle cx="10" cy="20" r="8" />
            <rect x="25" y="15" rx="5" ry="5" height="10" style={{ width: 'calc(100% - 28px)' }} />
        </ContentLoader>
    )
}

export function LoadingListAvatar2(props) {
    const auth = useSelector(state => state.auth);
    const [themeMode] = useState(auth.user.layout_theme ? auth.user.layout_theme : config.layout.theme);
    let primaryColor = themeMode === "DARK" ? colorDark : colorLight;
    let secondaryColor = themeMode === "DARK" ? colorDark : colorLight;

    return (
        <ContentLoader
            height={36}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            primaryOpacity={primaryOpacity}
            secondaryOpacity={secondaryOpacity}
            {...props}
        >
            <circle cx="20" cy="18" r="12" />
            <rect x="40" y="8" rx="4" ry="4" height="8" style={{ width: 'calc(100% - 48px)' }} />
            <rect x="40" y="20" rx="4" ry="4" height="8" style={{ width: 'calc(100% - 48px)' }} />
        </ContentLoader>
    )
}

export function LoadingBanner(props) {
    const auth = useSelector(state => state.auth);
    const [themeMode] = useState(auth.user.layout_theme ? auth.user.layout_theme : config.layout.theme);
    let primaryColor = themeMode === "DARK" ? colorDark : colorLight;
    let secondaryColor = themeMode === "DARK" ? colorDark : colorLight;

    return (
        <Grid container className="qr-banner">
            <div className="qr-banner-slider">
                <div className="qr-banner-content">
                    <ContentLoader
                        className="qr-banner-image"
                        primaryColor={primaryColor}
                        secondaryColor={secondaryColor}
                        primaryOpacity={primaryOpacity}
                        secondaryOpacity={secondaryOpacity}
                        {...props}
                    >
                        <rect x="0" y="0" width="100%" height="100%" />
                    </ContentLoader>
                </div>
            </div>
        </Grid>
    )
}

export function LoadingCategoryItem(props) {
    const auth = useSelector(state => state.auth);
    const [themeMode] = useState(auth.user.layout_theme ? auth.user.layout_theme : config.layout.theme);
    let primaryColor = themeMode === "DARK" ? colorDark : colorLight;
    let secondaryColor = themeMode === "DARK" ? colorDark : colorLight;

    return (
        <div className="category-list-item">
            <div className="category-list-item-content">
                <ContentLoader
                    className="category-image"
                    primaryColor={primaryColor}
                    secondaryColor={secondaryColor}
                    primaryOpacity={primaryOpacity}
                    secondaryOpacity={secondaryOpacity}
                >
                    <rect x="0" y="0" width="100%" height="100%" />
                </ContentLoader>
                <ContentLoader
                    className="category-text pt-1"
                    primaryColor={primaryColor}
                    secondaryColor={secondaryColor}
                    primaryOpacity={primaryOpacity}
                    secondaryOpacity={secondaryOpacity}
                >
                    <rect x="0" y="0" rx="16" ry="16" width="100%" height="32" />
                    <rect x="0" y="56" rx="16" ry="16" width="100%" height="32" />
                </ContentLoader>
            </div>
        </div>
    )
}

export function LoadingPrizeItem(props) {
    const auth = useSelector(state => state.auth);
    const [themeMode] = useState(auth.user.layout_theme ? auth.user.layout_theme : config.layout.theme);
    let primaryColor = themeMode === "DARK" ? colorDark : colorLight;
    let secondaryColor = themeMode === "DARK" ? colorDark : colorLight;

    return (
        <div className="prize-card">
            <div className="prize-card-content">
                <ContentLoader
                    className="prize-card-image rounded-lg"
                    primaryColor={primaryColor}
                    secondaryColor={secondaryColor}
                    primaryOpacity={primaryOpacity}
                    secondaryOpacity={secondaryOpacity}
                >
                    <rect x="0" y="0" width="100%" height="100%" />
                </ContentLoader>
                <ContentLoader
                    height={100}
                    className="pt-1"
                    primaryColor={primaryColor}
                    secondaryColor={secondaryColor}
                    primaryOpacity={primaryOpacity}
                    secondaryOpacity={secondaryOpacity}
                >
                    <rect x="0" y="0" rx="16" ry="16" width="100%" height="32" />
                    <rect x="0" y="56" rx="16" ry="16" width="100%" height="32" />
                </ContentLoader>
                <ContentLoader
                    height={40}
                    primaryColor={primaryColor}
                    secondaryColor={secondaryColor}
                    primaryOpacity={primaryOpacity}
                    secondaryOpacity={secondaryOpacity}
                >
                    <rect x="260" y="0" rx="16" ry="16" width="140" height="32" />
                </ContentLoader>
            </div>
        </div>
    )
}

export function LoadingFeedCategory(props) {
    const auth = useSelector(state => state.auth);
    const [themeMode] = useState(auth.user.layout_theme ? auth.user.layout_theme : config.layout.theme);
    let primaryColor = themeMode === "DARK" ? colorDark : colorLight;
    let secondaryColor = themeMode === "DARK" ? colorDark : colorLight;

    return (
        <Card className="card">
            <ContentLoader
                height={130}
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
                primaryOpacity={primaryOpacity}
                secondaryOpacity={secondaryOpacity}
            >
                <rect x="58" y="29" rx="4" ry="4" width="117" height="6" />
                <rect x="95" y="73" rx="3" ry="3" width="50" height="6" />
                <rect x="95" y="89" rx="3" ry="3" width="100" height="6" />
                <circle cx="27" cy="31" r="16" />
                <circle cx="44" cy="85" r="25" />
            </ContentLoader>
            <Divider />
            <ContentLoader
                height={40}
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
                primaryOpacity={primaryOpacity}
                secondaryOpacity={secondaryOpacity}
            >
                <rect x="12" y="19" rx="3" ry="3" width="95" height="6" />
                <rect x="345" y="19" rx="3" ry="3" width="20" height="6" />
            </ContentLoader>
        </Card>
    )
}

export function LoadingFeedText(props) {
    const auth = useSelector(state => state.auth);
    const [themeMode] = useState(auth.user.layout_theme ? auth.user.layout_theme : config.layout.theme);
    let primaryColor = themeMode === "DARK" ? colorDark : colorLight;
    let secondaryColor = themeMode === "DARK" ? colorDark : colorLight;

    return (
        <Card className="card">
            <ContentLoader
                height={110}
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
                primaryOpacity={primaryOpacity}
                secondaryOpacity={secondaryOpacity}
            >
                <rect x="58" y="29" rx="4" ry="4" width="117" height="6" />
                <rect x="12" y="67" rx="3" ry="3" width="380" height="6" />
                <rect x="12" y="79" rx="3" ry="3" width="380" height="6" />
                <rect x="12" y="91" rx="3" ry="3" width="201" height="6" />
                <circle cx="27" cy="31" r="16" />
            </ContentLoader>
            <Divider />
            <ContentLoader
                height={40}
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
                primaryOpacity={primaryOpacity}
                secondaryOpacity={secondaryOpacity}
            >
                <rect x="12" y="19" rx="3" ry="3" width="95" height="6" />
            </ContentLoader>
        </Card>
    )
}

export function LoadingFeedImage(props) {
    const auth = useSelector(state => state.auth);
    const [themeMode] = useState(auth.user.layout_theme ? auth.user.layout_theme : config.layout.theme);
    let primaryColor = themeMode === "DARK" ? colorDark : colorLight;
    let secondaryColor = themeMode === "DARK" ? colorDark : colorLight;

    return (
        <Card className="card">
            <ContentLoader
                height={280}
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
                primaryOpacity={primaryOpacity}
                secondaryOpacity={secondaryOpacity}
            >
                <rect x="0" y="0" rx="0" ry="0" width="400" height="220" />
                <rect x="12" y="233" rx="3" ry="3" width="380" height="6" />
                <rect x="12" y="246" rx="3" ry="3" width="320" height="6" />
            </ContentLoader>
            <Divider />
            <ContentLoader
                height={40}
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
                primaryOpacity={primaryOpacity}
                secondaryOpacity={secondaryOpacity}
            >
                <rect x="12" y="19" rx="3" ry="3" width="95" height="6" />
                <rect x="345" y="19" rx="3" ry="3" width="20" height="6" />
            </ContentLoader>
        </Card>
    )
}

export function LoadingFeedsList(props) {
    const { limit } = props;
    var loading = [];

    for (var i = 0; i < limit; i++) {
        if (i % 2 === 0) {
            loading.push(<LoadingFeedText key={i} />);
        } else {
            if (i % 2 === 1) {
                if (i === 3) {
                    loading.push(<LoadingFeedImage key={i} />);
                } else {
                    loading.push(<LoadingFeedCategory key={i} />);
                }
            }
        }
    }

    return (
        <Grid container className="content-padding">
            {loading}
        </Grid>
    )
}

export function LoadingCategoryList(props) {
    const { limit } = props;
    var loadingList = [];

    for (var i = 0; i < limit; i++) {
        loadingList.push(
            <Grid item key={i} xs={6} sm={3} md={4} lg={3}>
                <LoadingCategoryItem />
            </Grid>
        );
    }

    return (
        <Grid container spacing={2} className="category-list __fluid">
            {loadingList}
        </Grid>
    );
}

export function LoadingPrizeList(props) {
    const { limit } = props;
    var loadingList = [];

    for (var i = 0; i < limit; i++) {
        loadingList.push(
            <Grid item key={i} xs={6} sm={4} md={3}>
                <LoadingPrizeItem />
            </Grid>
        );
    }

    return (
        <Grid container spacing={2}>
            {loadingList}
        </Grid>
    );
}

export function LoadingCategoryGrid(props) {
    const { limit, action } = props;
    var loadingGrid = [];

    for (var i = 0; i < limit; i++) {
        loadingGrid.push(
            <div key={i}>
                <LoadingCategoryItem />
            </div>
        );
    }

    return (
        <SectionCategoryGrid action={action}>
            {loadingGrid}
        </SectionCategoryGrid>
    );
}

export function LoadingSectionIconList(props) {
    const { limit } = props;
    var loadingList = [];

    for (var i = 0; i < limit; i++) {
        loadingList.push(
            <ListItem key={i}>
                <LoadingListIcon1 />
            </ListItem>
        );
    }

    return (
        <SectionList seeAllButton={false}>
            {loadingList}
        </SectionList>
    );
}

export function LoadingAvatarList(props) {
    const { limit } = props;
    var loadingList = [];

    for (var i = 0; i < limit; i++) {
        loadingList.push(
            <Grid item key={i} xs={12}>
                <LoadingListAvatar2 />
            </Grid>
        );
    }

    return (
        <Grid container className="category-list __fluid">
            {loadingList}
        </Grid>
    );
}
