import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import config from '../../../config';
import useReactRouter from 'use-react-router';
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { actions as TrailActions } from '../../../redux/ducks/trail';
import { useLastLocation } from 'react-router-last-location';
import { numberMask } from '../../../utils/utils';
import {
    AppBar, Toolbar, IconButton, Typography, Container,
    Button, Divider, Grid, ListItem, ListItemText, Slide,
    Dialog, DialogContent, DialogTitle, TextField,
    useTheme, useMediaQuery
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { QrMenu } from '../../../components/QrMenu';
import QrContent from '../../../components/QrContent';
import EmptyContent from '../../../components/EmptyContent';
import ErrorContent from '../../../components/ErrorContent';
import SnackBar from '../../../components/snackBar';
import QrList from '../../../components/QrList';
import { LoadingContent, LoadingBounce } from '../../../components/Loading';
import { Icon, Points, Char, Ribbon, Rating } from '../../../components/Images/Images';
import LogoIcon from '../../../dist/img/projects/fleury/logos/LogoIcon';
import { generateChar } from '../../../utils/utils';
import ImgBackgroundWin1_1 from '../../../dist/img/banner/img_background_result_win_1_1.jpg';
import ImgBackgroundWin1_2 from '../../../dist/img/banner/img_background_result_win_1_2.jpg';
import ImgBackgroundWin2_1 from '../../../dist/img/banner/img_background_result_win_2_1.jpg';
import ImgBackgroundWin2_2 from '../../../dist/img/banner/img_background_result_win_2_2.jpg';
import ImgBackgroundLose from '../../../dist/img/banner/img_background_result_lose.jpg';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function StepResult(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const auth = useSelector(state => state.auth);
    const theme = useTheme();
    const step = props.location.state && props.location.state.step ? props.location.state.step : '';
    const session = props.location.state && props.location.state.session ? props.location.state.session : '';
    const origin = props.location.state && props.location.state.origin ? props.location.state.origin : '';
    const content = props.location.state && props.location.state.content ? props.location.state.content : '';
    const stepResult = props.location.state && props.location.state.stepResult ? props.location.state.stepResult : '';
    const result = useSelector(state => state.trail.result);
    const ratingSubmit = useSelector(state => state.trail.ratingSubmit);
    const [redirect, setRedirect] = useState(false);
    const [ratingDisplay, setRatingDisplay] = useState(false);
    const [openRating, setOpenRating] = useState(false);
    const [ratingValue, setRatingValue] = useState(null);
    const [ratingMessage, setRatingMessage] = useState("");
    const [loadingButtonRating, setLoadingButtonRating] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackStatus, setSnackStatus] = useState("error");
    const [snackMessage, setSnackMessage] = useState("");
    const [charColor, setCharColor] = useState(null);
    const [charGender, setCharGender] = useState(null);
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
    const lastLocation = useLastLocation();
    let data = stepResult.length !== 0 ? stepResult : (result && result.load === "finished") && result.data;

    useEffect(() => {
        if (lastLocation && (lastLocation.pathname.includes("flashcards") ||
            lastLocation.pathname.includes("questions") ||
            (lastLocation.pathname.includes("intro") && origin === "validation") ||
            (lastLocation.pathname.includes("scorm") && origin === "scorm"))) {
            setRedirect(false);
            setCharColor(generateChar(config.layout.char?.color ? config.layout.char.color : 'random', 'black', 'white'));
            setCharGender(generateChar(config.layout.char?.gender ? config.layout.char.gender : 'random', 'female', 'male'));
        } else {
            setRedirect(true);
        }

    }, [lastLocation, origin]);

    useEffect(() => {
        if (!stepResult) {
            dispatch(TrailActions.getStepCommit({
                id_step: session.id_step,
                id_session: session.id_session,
                refers_id: session.id_step,
                content: content
            }));
        }

        return () => {
            dispatch(TrailActions.reset());
        }

    }, [dispatch, session, content, props.location.state, origin, stepResult]);

    function selectBanner(i) {
        switch (i) {
            case 'female_black':
                return ImgBackgroundWin1_1
            case 'female_white':
                return ImgBackgroundWin1_2
            case 'male_black':
                return ImgBackgroundWin2_1
            case 'male_white':
                return ImgBackgroundWin2_2
            default:
                return;
        }
    }

    function goBack() {
        let currentOrigin = "result";

        switch (origin) {
            case 'flashcard':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}${!config.learn.trail.unified_stages.display ? `/${props.match.params.idStage}` : ''}`,
                    state: {
                        origin: currentOrigin
                    }
                })
            case 'questions':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}${!config.learn.trail.unified_stages.display ? `/${props.match.params.idStage}` : ''}`,
                    state: {
                        origin: currentOrigin
                    }
                })
            case 'scorm':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}${!config.learn.trail.unified_stages.display ? `/${props.match.params.idStage}` : ''}`,
                    state: {
                        origin: currentOrigin
                    }
                })
            default:
                return history.goBack()
        }
    }

    function actionButton(action) {
        switch (action) {
            case 'next':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}/${props.match.params.idStage}/intro`,
                    state: {
                        origin: 'result',
                        step: data.next_step
                    }
                })
            case 'retry':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}/${props.match.params.idStage}/intro`,
                    state: {
                        origin: 'result',
                        step: step
                    }
                })
            case 'exit':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}${!config.learn.trail.unified_stages.display ? `/${props.match.params.idStage}` : ''}`,
                    state: {
                        origin: 'result'
                    }
                })
            case 'exit-step':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}`,
                    state: {
                        origin: 'result'
                    }
                })
            case 'complete':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}/${props.match.params.idStage}/complete`,
                    state: {
                        origin: 'result',
                        stage: data
                    }
                })
            default:
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}${!config.learn.trail.unified_stages.display ? `/${props.match.params.idStage}` : ''}`,
                    state: {
                        origin: 'result'
                    }
                })
        }
    }

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-overlay" position="absolute" color="primary">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="close"
                        edge="start"
                        onClick={() => goBack()}
                    >
                        <Icon ic="close" />
                    </IconButton>
                </Toolbar>
            </AppBar>
        )
    }

    const Header = () => {
        return (
            <Grid container id="trail-steps-result-header" justify="center" className={`appbar-custom-content __stars-${data.step_result.grade}`}>
                <Container maxWidth="md">
                    <Grid container justify="center" spacing={2}>
                        <Grid item xs={12} sm={10} lg={8} xl={10} align="center">
                            <Ribbon
                                type="straight-chalk"
                                text={
                                    data.step_result.passed === 'Y' ?
                                        intl.get("TRAIL_STEP_RESULT_COMPLETE")
                                        :
                                        intl.get("TRAIL_STEP_RESULT_NO_COMPLETE")
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={10} lg={8} xl={10} align="center" className="result-char">
                            {
                                !config.learn.trail.chars || config.learn.trail.type !== 'stars' ?
                                    <div className={`result-char-content __stars-${data.step_result.grade}`}>
                                        {
                                            config.app.name_project === "FLEURY" ?
                                                <LogoIcon />
                                                :
                                                <Typography variant="h1" component="h3">
                                                    {step.step_number}
                                                </Typography>
                                        }
                                    </div>
                                    : null
                            }
                            <Char type={`trail-finished-stars-${data.step_result.grade}`} />
                        </Grid>
                        <Grid item xs={12} sm={10} lg={8} xl={10} align="center">
                            <Typography variant="h4" component="h2">
                                {
                                    step.step_type === "CODE" ?
                                        intl.get("TRAIL_STEP_CODE_TITLE")
                                        :
                                        step.step_number_description
                                }
                            </Typography>
                            <Typography variant="body1" component="p">
                                {step.step_name}
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        )
    }

    const HeaderImage = () => {
        const StageStars = ({ type }) => {
            switch (type) {
                case "0":
                    return (
                        <>
                            <Icon ic="star_outline" className="icon-lg" />
                            <Icon ic="star_outline" className="icon-xl mr-2 ml-2" />
                            <Icon ic="star_outline" className="icon-lg" />
                        </>
                    )
                case "1":
                    return (
                        <>
                            <Icon ic="star_filled" className="icon-lg" />
                            <Icon ic="star_outline" className="icon-xl mr-2 ml-2" />
                            <Icon ic="star_outline" className="icon-lg" />
                        </>
                    )
                case "2":
                    return (
                        <>
                            <Icon ic="star_filled" className="icon-lg" />
                            <Icon ic="star_filled" className="icon-xl mr-2 ml-2" />
                            <Icon ic="star_outline" className="icon-lg" />
                        </>
                    )
                case "3":
                    return (
                        <>
                            <Icon ic="star_filled" className="icon-lg" />
                            <Icon ic="star_filled" className="icon-xl mr-2 ml-2" />
                            <Icon ic="star_filled" className="icon-lg" />
                        </>
                    )
                default:
                    return <Grid item xs={12} sm={8} xl={10} align="center"></Grid>;
            }
        }

        return (
            <Grid
                container
                id="trail-steps-result-header"
                justify="center"
                className="appbar-custom-content __no-vector"
                style={{ backgroundImage: `url(${data.step_result.passed === 'Y' ? selectBanner(`${charGender}_${charColor}`) : ImgBackgroundLose})`, backgroundPositionY: data.step_result.passed === 'Y' ? 'center' : 'top' }}
            >
                <div className='__filter'>
                    <Container maxWidth="md" className="pb-3 h-100">
                        <Grid container alignContent="flex-end" justify="center" spacing={1} className="h-100">
                            {
                                config.learn.trail.type === 'stars' ?
                                    <Grid item xs={12} sm={8} xl={10} align="center">
                                        <StageStars type={data.step_result.grade} />
                                    </Grid>
                                    : null
                            }
                            <Grid item xs={12} sm={8} xl={10} align="center">
                                <Typography variant="h2" component="h1">
                                    {
                                        data.step_result.passed === 'Y' ?
                                            intl.get("TRAIL_STEP_RESULT_COMPLETE")
                                            :
                                            intl.get("TRAIL_STEP_RESULT_NO_COMPLETE")
                                    }
                                </Typography>
                                <Typography variant="body1" component="p">
                                    {`${step.step_number_description ? step.step_number_description + " - " : ""}${step.step_name}`}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </Grid>
        )
    }

    const ListResult = () => {
        auth.user.qi_credit = data.user.qi_credit;
        auth.user.total_points = data.user.total_points;
        localStorage.setItem('auth.state.user', JSON.stringify(auth.user));

        return (
            <Container maxWidth="md" className="pb-3">
                <Grid container justify="center" spacing={2}>
                    <Grid item xs={12} sm={8} xl={10}>
                        <QrList>
                            {
                                data.step_result.total_answered ?
                                    <>
                                        <ListItem disableGutters>
                                            <ListItemText
                                                primary={intl.get("LABEL_CORRECT_ANSWERS")}
                                                secondary={`${data.step_result.total_right} / ${data.step_result.total_answered}`} />
                                        </ListItem>
                                        <Divider />
                                    </>
                                    : null
                            }
                            <ListItem disableGutters>
                                <ListItemText
                                    disableTypography
                                    primary={
                                        <div>
                                            <Typography component="span" variant="body1">
                                                {intl.get("LABEL_XP")}
                                            </Typography>
                                            <Typography component="span" variant="body1"
                                                className={`${data.step_result.points_score > 0 ? "text-success" : "text-lighter"} ml-2`}
                                            >
                                                {data.step_result.points_score > 0 ? "+" : ""}
                                                {data.step_result.points_score}
                                            </Typography>
                                        </div>
                                    }
                                    secondary={
                                        <div className="d-flex flex-row align-items-center">
                                            <Points type="xp" className="img-xs mr-1" />
                                            <Typography variant="body1" className="text-xp" component="span">
                                                {numberMask(data.user.total_points)}
                                            </Typography>
                                        </div>
                                    }
                                />
                            </ListItem>
                            {
                                config.layout.points.qis ?
                                    <>
                                        <Divider />
                                        <ListItem disableGutters>
                                            <ListItemText
                                                disableTypography
                                                primary={
                                                    <div>
                                                        <Typography component="span" variant="body1">
                                                            {intl.get("LABEL_QIS")}
                                                        </Typography>
                                                        <Typography component="span" variant="body1"
                                                            className={`${data.step_result.qi_score > 0 ? "text-success" : "text-lighter"} ml-2`}
                                                        >
                                                            {data.step_result.qi_score > 0 ? "+" : ""}
                                                            {data.step_result.qi_score}
                                                        </Typography>
                                                        {data.step_result.bonus_multiplier ?
                                                            <span class="badge badge-pill badge-success ml-1">
                                                                {Math.round(data.step_result.bonus_multiplier) + "x"}
                                                            </span>
                                                            : null
                                                        }
                                                    </div>
                                                }
                                                secondary={
                                                    <div className="d-flex flex-row align-items-center">
                                                        <Points type="qis" className="img-xs mr-1" />
                                                        <Typography variant="body1" className="text-qis" component="span">
                                                            {numberMask(data.user.qi_credit)}
                                                        </Typography>
                                                    </div>
                                                }
                                            />
                                        </ListItem>
                                    </>
                                    : null
                            }
                        </QrList>
                    </Grid>
                </Grid>
            </Container>
        )
    }

    const handleRating = (event, rating) => {
        setRatingValue(rating);
    };

    async function sendRating() {
        setLoadingButtonRating(true);
        dispatch(TrailActions.setRatingSubmit({
            id_course: props.match.params.idTrail,
            rating_star: ratingValue,
            rating_comment: ratingMessage
        }));
    }

    useEffect(() => {
        if (loadingButtonRating && ratingSubmit?.data?.success === "ok") {
            setLoadingButtonRating(false);
            setOpenRating(false);
            setSnackStatus("success");
            setSnackOpen(true);
            setSnackMessage(intl.get("SUCCESS_TRAIL_USER_RATING"));
            dispatch(TrailActions.resetRatingSubmit({}));
        }

        if (loadingButtonRating && ratingSubmit?.error) {
            setLoadingButtonRating(false);
            setOpenRating(false);
            setSnackStatus("error");
            setSnackOpen(true);
            setSnackMessage(ratingSubmit.messages);
            dispatch(TrailActions.resetRatingSubmit({}));
        }
    }, [loadingButtonRating, ratingSubmit, dispatch]);

    useEffect(() => {
        if ((data?.course_completed === "Y" && data?.show_feedback === "Y") && !ratingDisplay) {
            setRatingDisplay(true);
            setOpenRating(true);
        }
    }, [data, ratingDisplay]);

    return (
        !redirect ?
            <div id="qr-wrap">
                <QrMenu />
                <div id="qr-content">
                    <QrContent id="trail-steps-result" hideSidebar>
                        {
                            config.layout.navbar ?
                                <QrAppBar />
                                : null
                        }
                        {
                            data ?
                                !result.error ?
                                    data.length !== 0 ?
                                        <div>
                                            {/* Header */}
                                            {
                                                config.learn.trail.vector_images ?
                                                    <Header />
                                                    :
                                                    charColor && charGender ?
                                                        <HeaderImage />
                                                        : null
                                            }

                                            {/* List */}
                                            <ListResult />

                                            {/* Buttons */}
                                            <Container maxWidth="md" className="pb-4">
                                                <Grid container justify="center">
                                                    <Grid item xs={12} sm={8}>
                                                        <div id="bottom-bar" className="relative-bar">
                                                            {
                                                                data.step_result.passed === 'Y' ?
                                                                    <Grid container spacing={2} justify="center">
                                                                        {
                                                                            origin === "validation" ?
                                                                                <Grid item xs={data.next_step && data.next_step.available === "Y" ? 6 : 12}>
                                                                                    <Button id="btn_trail_step_result_exit" variant="contained" className="btn-block" onClick={() => actionButton("exit")}>
                                                                                        <Icon ic="exit" className="mr-2" /> {intl.get("BTN_EXIT")}
                                                                                    </Button>
                                                                                </Grid>
                                                                                :
                                                                                session.step_type && session.step_type === "CHECK" ?
                                                                                    <Grid item xs={6}>
                                                                                        <Button id="btn_trail_step_result_exit" variant="contained" className="btn-block" onClick={() => actionButton("exit")}>
                                                                                            <Icon ic="exit" className="mr-2" /> {intl.get("BTN_EXIT")}
                                                                                        </Button>
                                                                                    </Grid>
                                                                                    :
                                                                                    <Grid item xs={6}>
                                                                                        <Button id="btn_trail_step_result_retry" variant="contained" className="btn-block" onClick={() => actionButton("retry")}>
                                                                                            <Icon ic="replay" className="mr-2" /> {intl.get("BTN_REDO")}
                                                                                        </Button>
                                                                                    </Grid>

                                                                        }
                                                                        {
                                                                            data.next_step && data.next_step.available === "Y" ?
                                                                                <Grid item xs={6}>
                                                                                    <Button id="btn_trail_step_result_next" variant="contained" className="btn-block" onClick={() => actionButton(data.stage_result ? "complete" : "next")}>
                                                                                        {intl.get("BTN_CONTINUE")} <Icon ic="arrow_right" className="ml-2" />
                                                                                    </Button>
                                                                                </Grid>
                                                                                :
                                                                                origin !== "validation" && !(session.step_type && session.step_type === "CHECK") ?
                                                                                    <Grid item xs={6}>
                                                                                        <Button id="btn_trail_step_result_exit" variant="contained" className="btn-block" onClick={() => actionButton("exit-step")}>
                                                                                            <Icon ic="exit" className="mr-2" /> {intl.get("BTN_EXIT")}
                                                                                        </Button>
                                                                                    </Grid>
                                                                                    :
                                                                                    null
                                                                        }
                                                                    </Grid>
                                                                    :
                                                                    <Grid container spacing={2} justify="center">
                                                                        <Grid item xs={6}>
                                                                            <Button id="btn_trail_step_result_exit" variant="contained" className="btn-block" onClick={() => actionButton("exit")}>
                                                                                <Icon ic="exit" className="mr-2" /> {intl.get("BTN_EXIT")}
                                                                            </Button>
                                                                        </Grid>
                                                                        {
                                                                            origin !== "validation" && !(session.step_type && session.step_type === "CHECK") ?
                                                                                <Grid item xs={6}>
                                                                                    <Button id="btn_trail_step_result_retry" variant="contained" className="btn-block" onClick={() => actionButton("retry")}>
                                                                                        {intl.get("BTN_REDO")} <Icon ic="replay" className="ml-2" />
                                                                                    </Button>
                                                                                </Grid>
                                                                                :
                                                                                null
                                                                        }
                                                                    </Grid>
                                                            }
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </Container>
                                        </div>
                                        :
                                        <Container maxWidth="md">
                                            <EmptyContent icon={<Icon ic="play_trail" className="icon-xxl" />} text={intl.get("EMPTY_TRAIL_STEPS_CONTENT")} />
                                        </Container>
                                    :
                                    <Container maxWidth="md">
                                        <ErrorContent />
                                    </Container>
                                :
                                <Container maxWidth="md">
                                    <LoadingContent />
                                </Container>
                        }
                    </QrContent>
                    <Dialog
                        id="dialog-trail-rating"
                        open={openRating}
                        onClose={() => setOpenRating(false)}
                        aria-labelledby="dialog-trail-rating"
                        TransitionComponent={Transition}
                        maxWidth="sm"
                        fullWidth
                        disableBackdropClick
                        disableEscapeKeyDown
                    >
                        <DialogTitle>
                            {intl.get('TRAIL_RATING_FEEDBACK_TITLE')}
                        </DialogTitle>
                        <DialogContent>
                            {
                                !loadingButtonRating ?
                                    <Grid container justify="center" spacing={2}>
                                        <Grid item xs={12} align="center">
                                            <ToggleButtonGroup
                                                value={ratingValue}
                                                exclusive
                                                onChange={handleRating}
                                                aria-label="text alignment"
                                            >
                                                <ToggleButton value="1" aria-label="rating1">
                                                    <Rating type="1" className={isMobile ? "img-md" : "img-lg"} />
                                                </ToggleButton>
                                                <ToggleButton value="2" aria-label="rating2">
                                                    <Rating type="2" className={isMobile ? "img-md" : "img-lg"} />
                                                </ToggleButton>
                                                <ToggleButton value="3" aria-label="rating3">
                                                    <Rating type="3" className={isMobile ? "img-md" : "img-lg"} />
                                                </ToggleButton>
                                                <ToggleButton value="4" aria-label="rating4">
                                                    <Rating type="4" className={isMobile ? "img-md" : "img-lg"} />
                                                </ToggleButton>
                                                <ToggleButton value="5" aria-label="rating5">
                                                    <Rating type="5" className={isMobile ? "img-md" : "img-lg"} />
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                        </Grid>
                                        <Grid item xs={12} align="center">
                                            <TextField
                                                name="feedback"
                                                type="text"
                                                maxlength="255"
                                                onChange={(e) => setRatingMessage(e.target.value)}
                                                label={intl.get('LABEL_GIVE_YOUR_FEEDBACK')}
                                                variant={config.layout.input_variant}
                                                fullWidth
                                                multiline
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} align="center" className="mb-3">
                                            <Button id="btn_trail_rating_send" variant="contained" className="btn-block" disabled={ratingValue ? false : true} onClick={() => sendRating()}>
                                                {intl.get("BTN_SEND")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    :
                                    <Grid container justify="center" className="pt-5 pb-5">
                                        <LoadingBounce />
                                    </Grid>
                            }
                        </DialogContent>
                    </Dialog>
                    <SnackBar
                        open={snackOpen}
                        message={snackMessage}
                        status={snackStatus}
                        time={4}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        closeSnack={() => setSnackOpen(false)}
                    />
                </div>
            </div>
            :
            <Redirect to={{
                pathname: `${props.match.path.replace(/./g, '')}/trail/${props.match.params.idTrail}${!config.learn.trail.unified_stages.display ? `/${props.match.params.idStage}` : ''}`,
                state: {
                    origin: 'result'
                }
            }} />
    )
}
