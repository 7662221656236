import React, { useState, useEffect, forwardRef } from 'react';
import intl from 'react-intl-universal';
import config from '../../../config';
import useReactRouter from 'use-react-router'
import { useDispatch, useSelector } from 'react-redux';
import { actions as ClassroomActions } from '../../../redux/ducks/classroom_course';
import { classroomService } from '../../../services/classroom.service';
import { imageParser, getMonth, getDate, getHours, diffMoment, getDateTime } from '../../../utils/utils';
import { desaturate } from 'polished';
import ReactHtmlParser from 'react-html-parser';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import {
    Grid, Divider, Box, Typography, Button, TextField,
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
    List, ListItem, ListItemIcon, ListItemText, Slide, Container,
    AppBar, Toolbar, IconButton
} from '@material-ui/core'
import { QrMenu } from '../../../components/QrMenu';
import QrContent from '../../../components/QrContent';
import { Icon } from '../../../components/Images/Images';
import EmptyContent from '../../../components/EmptyContent';
import ErrorContent from '../../../components/ErrorContent';
import { LoadingBounce, LoadingContent } from '../../../components/Loading';
import SnackBar from '../../../components/snackBar';
import ButtonCertificate from '../../../components/ButtonCertificate';
import ButtonSurvey from '../../../components/ButtonSurvey';
import DialogMaterial from './components/DialogMaterial';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ClassroomDetails(props) {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [didMount, setDidMount] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [materialItem, setMaterialItem] = useState({});
    const [materialOpen, setMaterialOpen] = useState(false);
    const classroom = useSelector(state => state.classroom_course);
    const { history } = useReactRouter();
    const stc = require('string-to-color');
    let isSoldOff = (parseInt(classroom.details.total_subscription) >= parseInt(classroom.details.room_capacity) ? true : false);
    let isCourseFinished = !diffMoment(classroom.details.date_end_training) ? true : false;
    let isUserEnabledSubscription = classroom.details.training_enable_user_subscription && classroom.details.training_enable_user_subscription === 'Y' ? true : false;
    let isUserSubscription = classroom.details.user_subscription && classroom.details.user_subscription === 'Y' ? true : false;
    let isUserComplete = classroom.details.user_complete && classroom.details.user_complete === 'Y' ? true : false;
    let isEnableSubscription = classroom.details.enable_subscription && classroom.details.enable_subscription === 'Y' ? true : false;
    let isUserSubscribedManager = !isEnableSubscription && isUserSubscription ? true : false;
    let isStartSubscription = classroom.details.training_subscribe_start ? !diffMoment(classroom.details.training_subscribe_start) : true;
    let isNotEndSubscription = classroom.details.training_subscribe_end ? diffMoment(classroom.details.training_subscribe_end) : true;
    let isCourseStarted = !diffMoment(classroom.details.date_start_training) ? true : false;

    useEffect(() => {
        setDidMount(true);
        if (didMount) {
            dispatch(ClassroomActions.details({
                id_training: props.match.params.idClassroomCourse
            }));
        }
        return () => {
            dispatch(ClassroomActions.clear());
            setDidMount(false);
        }
    }, [didMount, dispatch, props.match])

    async function sendSubscribe() {
        setLoadingButton(true);
        handleClose();
        await dispatch(ClassroomActions.subscribe({
            id_training: classroom.details.id_training
        }));
        await dispatch(ClassroomActions.details({
            id_training: classroom.details.id_training
        }));
        setLoadingButton(false);
    }

    async function sendSubscribeCode(data) {
        setLoadingButton(true);
        handleClose();
        await dispatch(ClassroomActions.subscribe({
            id_training: classroom.details.id_training,
            validation_code: data.subscription_code
        }));
        await dispatch(ClassroomActions.details({
            id_training: classroom.details.id_training
        }));
        setLoadingButton(false);
    }

    async function sendUnsubscribe() {
        setLoadingButton(true);
        await dispatch(ClassroomActions.unsubscribe({
            id_training: classroom.details.id_training
        }));
        await dispatch(ClassroomActions.details({
            id_training: classroom.details.id_training
        }));
        setLoadingButton(false);
    }

    async function downloadCertificate() {
        let ret;
        setLoadingButton(true);
        try {
            ret = await classroomService.certificate({
                format: "pdf",
                id_training: classroom.details.id_training
            });
            let link = document.createElement("a");
            link.download = "Certificado.pdf";
            link.href = URL.createObjectURL(new Blob([ret], { type: 'application/pdf' }));
            link.click();
            setLoadingButton(false);
        }
        catch (err) {
            console.log(err)
        }
    }

    function materialContent(item) {
        setMaterialItem(item);
        setMaterialOpen(true);
    }

    const ButtonSubscription = (props) => {
        const { text, disabled } = props;

        return (
            loadingButton ?
                <LoadingBounce size={24} />
                :
                <Button
                    id="btn_classroom_subscription"
                    variant="contained"
                    className="btn-block"
                    onClick={() => {
                        classroom.details.training_have_subscribed_code === 'Y' ?
                            handleClickOpen()
                            :
                            sendSubscribe()
                    }}
                    disabled={disabled ? disabled : false}
                >
                    {text}
                </Button>
        )
    }

    const ButtonUnsubscription = (props) => {
        const { text, disabled } = props;

        return (
            loadingButton ?
                <LoadingBounce size={24} />
                :
                <Button
                    id="btn_classroom_unsubscribe"
                    variant="contained"
                    className="btn-block"
                    disabled={disabled ? disabled : false}
                    onClick={() => sendUnsubscribe()}
                >
                    {text}
                </Button>
        )
    }

    const ButtonDisabled = (props) => {
        const { text } = props;

        return (
            loadingButton ?
                <LoadingBounce size={24} />
                :
                <Button
                    id="btn_classroom_subscription_disabled"
                    variant="contained"
                    className="btn-block"
                    disabled={true}
                >
                    {text}
                </Button>
        )
    }

    const ButtonCertificateClassroom = () => {
        return (
            <Grid item xs={12} sm={10} align="center">
                {
                    loadingButton ?
                        <LoadingBounce size={24} />
                        :
                        <ButtonCertificate
                            action={() => downloadCertificate()}
                            unlock={classroom.details.certificate_available && classroom.details.certificate_available === "Y" ? true : false}
                            text={intl.get('CLASSROOM_CERTIFICATE_TEXT')}
                            textDisabled={
                                isUserSubscription && isUserComplete && isCourseStarted && classroom.details.has_survey === "Y" && (classroom.details.survey_answered && classroom.details.survey_answered === "N") ?
                                    intl.get('CLASSROOM_CERTIFICATE_TEXT_SURVEY_DISABLED')
                                    : intl.get('CLASSROOM_CERTIFICATE_TEXT_DISABLED')
                            }
                        />
                }
            </Grid>
        )
    }

    const Survey = () => {
        return (
            classroom.details.has_survey === "Y" ?
                <Grid item xs={12} sm={10}>
                    <ButtonSurvey
                        action={() => history.push({
                            pathname: `${props.match.path.replace(/./g, '')}/classroom/${classroom.details.id_training}/survey`,
                            state: {
                                data: classroom.details,
                                idName: 'id_training',
                                title: classroom.details.training_theme,
                                origin: 'classroom'
                            }
                        })}
                        answered={classroom.details.survey_answered === "Y"}
                        unlock={isUserSubscription && isUserComplete && isCourseStarted && classroom.details.survey_answered === "N"}
                        textUnlock={intl.get('CLASSROOM_SURVEY_TEXT_UNLOCK')}
                        textAnswered={intl.get('CLASSROOM_SURVEY_TEXT_ANSWERED')}
                        textDisabled={intl.get('CLASSROOM_SURVEY_TEXT_DISABLED')}
                    />
                </Grid>
                : null
        )
    }

    const ActionButton = () => {
        return (
            !(isUserSubscription && isUserComplete && isCourseStarted) ?
                <Grid item xs={12} sm={8} md={6} lg={4} align="center">
                    <div id="bottom-bar" className="relative-bar">
                        {
                            !isUserSubscription && isCourseFinished ?
                                <ButtonDisabled text={intl.get('CLASSROOM_FINISHED_LABEL')} />
                                // : isUserEnabledSubscription && !isCourseStarted ?
                                : isUserEnabledSubscription ?
                                    <ButtonSubscription text={intl.get('CLASSROOM_SUBSCRIBE_BUTTON')} />
                                    : isEnableSubscription && !isCourseFinished && !isStartSubscription ?
                                        <div className="w-100 text-center">
                                            <Typography variant="body2" component="p" className="mb-2">
                                                <strong>{`${intl.get('CLASSROOM_SUBSCRIBE_PERIOD_TITLE')}: `}</strong>
                                                {classroom.details.training_subscribe_start && " " + intl.get('CLASSROOM_SUBSCRIBE_PERIOD_TEXT_1') + " " + getDateTime(classroom.details.training_subscribe_start)}
                                                {classroom.details.training_subscribe_end && " " + intl.get('CLASSROOM_SUBSCRIBE_PERIOD_TEXT_2') + " " + getDateTime(classroom.details.training_subscribe_end)}
                                            </Typography>
                                            <ButtonSubscription text={intl.get('CLASSROOM_SUBSCRIBE_PERIOD_BUTTON')} disabled={true} />
                                        </div>
                                        : isUserSubscription && !isUserSubscribedManager && !isCourseStarted && !isCourseFinished ?
                                            <ButtonUnsubscription text={intl.get('CLASSROOM_UNSUBSCRIBE_BUTTON')} />
                                            : isUserSubscribedManager && !isCourseFinished ?
                                                <ButtonUnsubscription text={intl.get('CLASSROOM_NO_UNSUBSCRIBE_BUTTON')} disabled={true} />
                                                : !isUserSubscription && isSoldOff && !isCourseStarted && !isCourseFinished ?
                                                    <ButtonDisabled text={intl.get('CLASSROOM_SOLD_OFF_LABEL')} />
                                                    : !isUserSubscription && !isNotEndSubscription && !isCourseStarted && !isCourseFinished ?
                                                        <ButtonDisabled text={intl.get('CLASSROOM_EXPIRED_BUTTON')} />
                                                        : isUserSubscription && !isUserComplete && isCourseFinished ?
                                                            <ButtonDisabled text={intl.get('CLASSROOM_ABSENT_LABEL')} />
                                                            : !isUserSubscription ?
                                                                <ButtonDisabled text={intl.get('CLASSROOM_NO_SUBSCRIBE_BUTTON')} />
                                                                : null
                        }
                    </div>
                </Grid>
                :
                null
        )
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    if (classroom.error) {
        setSnackMessage(classroom.error);
        setSnackOpen(true);
        dispatch(ClassroomActions.clearError({}));
    }

    const ScheduleClass = () => {
        return (
            <Grid item xs={12} sm={10}>
                <div className="subheader-content">
                    <Typography variant="subtitle2" component="h4">
                        {intl.get("CLASSROOM_SCHEDULE_SUBHEADER")}
                    </Typography>
                </div>
                {
                    classroom.details.class_info.map((item, index) => {
                        return (
                            <div key={index} className="classroom-schedule">
                                <div className="classroom-schedule-header">
                                    <div className="calendar-content">
                                        <div className="calendar-date">
                                            <Typography variant="body2" className="day" component="p" noWrap>
                                                {getDate(item.class_date_start)}
                                            </Typography>
                                            <Typography variant="caption" className="month" component="p" noWrap>
                                                {getMonth(item.class_date_start)}
                                            </Typography>
                                        </div>
                                    </div>
                                    {
                                        isUserSubscription ?
                                            isCourseFinished ?
                                                item.user_presence === "Y" ?
                                                    <div className="badge badge-pill badge-success d-flex align-items-center">
                                                        <Icon ic="checked" className="icon-xxs mr-1" /> {intl.get('CLASSROOM_PRESENT_LABEL')}
                                                    </div>
                                                    :
                                                    item.user_presence === "N" ?
                                                        <div className="badge badge-pill badge-danger d-flex align-items-center">
                                                            <Icon ic="multiply" className="icon-xxs mr-1" /> {intl.get('CLASSROOM_ABSENT_LABEL')}
                                                        </div>
                                                        :
                                                        <div className="badge badge-pill badge-info d-flex align-items-center">
                                                            <Icon ic="hourglass" className="icon-xxs mr-1" /> {intl.get('CLASSROOM_WAITING_VALIDATION_LABEL')}
                                                        </div>
                                                :
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={() => history.push({
                                                        pathname: `${props.match.url}/ticket/${item.class_id}`,
                                                        state: {
                                                            class_id: item.class_id,
                                                            id_training: classroom.details.id_training
                                                        }
                                                    })}>
                                                    <Icon ic="qrcode" className="icon-xs mr-2" /> {intl.get('CLASSROOM_TICKET_BUTTON')}
                                                </Button>
                                            : null
                                    }
                                </div>
                                <div className="classroom-schedule-content">
                                    <div className="line"></div>
                                    <div className="class-info">
                                        <div className="class-info-icon">
                                            <Icon ic="clock" className="icon-xs" />
                                        </div>
                                        <div className="class-info-text">
                                            {
                                                item.class_name ?
                                                    <Typography variant="body1" component="p">
                                                        {item.class_name}
                                                    </Typography>
                                                    : null
                                            }
                                            <Typography variant="body2" component="p">
                                                {`${intl.get("LABEL_START")}: ${getHours(item.class_date_start)}`}
                                            </Typography>
                                            <Typography variant="body2" component="p">
                                                {`${intl.get("LABEL_END")}: ${getHours(item.class_date_end)}`}
                                            </Typography>
                                        </div>
                                    </div>
                                    {
                                        <div className="class-info">
                                            <div className="class-info-icon">
                                                <Icon ic="map_marker" className="icon-xs" />
                                            </div>
                                            <div className="class-info-text">
                                                <Typography variant="body1" component="p">
                                                    {item.local_name}
                                                </Typography>
                                                {
                                                    item.room_name ?
                                                        <Typography variant="body2" component="p">
                                                            {item.room_name}
                                                        </Typography>
                                                        : null
                                                }
                                                {
                                                    item.local_address || item.local_number || item.local_neighborhood ?
                                                        <Typography variant="body2" component="p">
                                                            {item.local_address && item.local_address}
                                                            {item.local_address && item.local_number && ', '}
                                                            {item.local_number && item.local_number}
                                                            {(item.local_address || item.local_number) && item.local_complement && ' - '}
                                                            {item.local_complement && item.local_complement}
                                                            {(item.local_address || item.local_number || item.local_complement) && item.local_neighborhood && ' - '}
                                                            {item.local_neighborhood && item.local_neighborhood}
                                                        </Typography>
                                                        : null
                                                }
                                                {
                                                    item.local_city || item.local_state ?
                                                        <Typography variant="body2" component="p">
                                                            {item.local_city && item.local_city}
                                                            {item.local_city && item.local_state && ' - '}
                                                            {item.local_state && item.local_state}
                                                        </Typography>
                                                        : null
                                                }
                                                {
                                                    item.local_zip_code ?
                                                        <Typography variant="body2" component="p">
                                                            {item.local_zip_code}
                                                        </Typography>
                                                        : null
                                                }
                                                {
                                                    item.local_phone ?
                                                        <Typography variant="body2" component="p">
                                                            {`${intl.get("LABEL_PHONE").substr(0, 3)}: ${item.local_phone}`}
                                                        </Typography>
                                                        : null
                                                }
                                                {
                                                    item.local_information ?
                                                        <Typography variant="body2" component="p">
                                                            <Icon ic="info" color="info" className="icon-xxxs" /> {item.local_information}
                                                        </Typography>
                                                        : null
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </Grid>
        )
    }

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-overlay" position="absolute">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="back"
                        edge="start"
                        onClick={() => history.goBack()}
                    >
                        <Icon ic="back" />
                    </IconButton>
                    <div className="w-100 overflow-hidden"></div>
                </Toolbar>
            </AppBar>
        )
    }

    return (
        <div id="qr-wrap">
            <QrMenu />
            <div id="qr-content">
                <QrContent id="classroom-details" hideSidebar>
                    {
                        config.layout.navbar ?
                            <QrAppBar />
                            : null
                    }
                    {
                        (classroom.status === "finished" && classroom.details.length !== 0) || loadingButton ?
                            <div>
                                <div
                                    id="classroom-details-header"
                                    className="appbar-custom-content"
                                    style={
                                        classroom.details.training_image || classroom.details.training_banner ?
                                            { backgroundImage: `url(${imageParser(classroom.details.training_banner ? classroom.details.training_banner : classroom.details.training_image)})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }
                                            :
                                            { backgroundColor: desaturate(0.25, stc(classroom.details.training_name ? classroom.details.training_name : classroom.details.training_theme)) }
                                    }
                                ></div>
                                <Container id="classroom-details-content" maxWidth="md" className="pt-3 pb-3">
                                    <Grid container justify="center" spacing={2}>
                                        <Grid item xs={12} sm={10}>
                                            <Typography variant="h3" component="h2">
                                                {classroom.details.training_theme}
                                            </Typography>
                                            {classroom.details.training_name ?
                                                <Typography variant="h5" component="h3" className="mt-1">
                                                    {classroom.details.training_name}
                                                </Typography>
                                                : null
                                            }
                                        </Grid>
                                        {
                                            // ### Descrição ###
                                            classroom.details.training_description ?
                                                <Grid item xs={12} sm={10}>
                                                    <Divider className="mb-3" />
                                                    <Typography variant="body2" component="div">
                                                        {ReactHtmlParser(classroom.details.training_description)}
                                                    </Typography>
                                                </Grid>
                                                : null
                                        }
                                        <Grid item xs={12} sm={10}>
                                            <Box p={2} className="highlight-box">
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <div className="d-flex align-items-center flex-nowrap">
                                                            <Icon ic="tuition" className="icon-sm mr-3" />
                                                            <div>
                                                                <Typography variant="caption" component="p">
                                                                    {intl.get("CLASSROOM_INSTRUCTOR_LABEL")}
                                                                </Typography>
                                                                <Typography variant="body1" component="p">
                                                                    {classroom.details.name_instructor}
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div className="d-flex align-items-center flex-nowrap">
                                                            <Icon ic="team" className="icon-sm mr-3" />
                                                            <div>
                                                                <Typography variant="caption" component="p">
                                                                    {intl.get("CLASSROOM_PARTICIPANTS_LABEL")}
                                                                </Typography>
                                                                <Typography variant="body1" component="p">
                                                                    {`${classroom.details.total_subscription} / ${classroom.details.room_capacity}`}
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>

                                        {/* Certificate */}
                                        <ButtonCertificateClassroom />

                                        {/* Survey */}
                                        <Survey />

                                        <ActionButton />
                                        {
                                            // ### Conteúdo Programático ###
                                            classroom.details.training_program_content ?
                                                <Grid item xs={12} sm={10}>
                                                    <div className="subheader-content">
                                                        <Typography variant="subtitle2" component="h4">
                                                            {intl.get("CLASSROOM_PROGRAM_CONTENT_SUBHEADER")}
                                                        </Typography>
                                                    </div>
                                                    <Typography variant="body2" component="div">
                                                        {ReactHtmlParser(classroom.details.training_program_content)}
                                                    </Typography>
                                                </Grid>
                                                : null
                                        }
                                        {
                                            // ### Informações Adicionais ###
                                            isUserSubscription && classroom.details.training_information_subscribed ?
                                                <Grid item xs={12} sm={10}>
                                                    <div className="subheader-content">
                                                        <Typography variant="subtitle2" component="h4">
                                                            {intl.get("CLASSROOM_ADDITIONAL_INFORMATION_SUBHEADER")}
                                                        </Typography>
                                                    </div>
                                                    <Typography variant="body2" component="div">
                                                        {ReactHtmlParser(classroom.details.training_information_subscribed)}
                                                    </Typography>
                                                </Grid>
                                                : null
                                        }
                                        {
                                            // ### Materiais ###
                                            classroom.details.training_material.length > 0 ?
                                                <Grid item xs={12} sm={10}>
                                                    <div className="subheader-content">
                                                        <Typography variant="subtitle2" component="h4">
                                                            {intl.get("CLASSROOM_MATERIALS_SUBHEADER")}
                                                        </Typography>
                                                    </div>
                                                    <List component="nav">
                                                        {
                                                            classroom.details.training_material.map((item, index) => {
                                                                return (
                                                                    <div key={index}>
                                                                        <ListItem button dense onClick={() => materialContent(item)}>
                                                                            <ListItemIcon>
                                                                                <Icon ic="attachment" color="lighter" />
                                                                            </ListItemIcon>
                                                                            <ListItemText
                                                                                primary={item.title}
                                                                                secondary={item.description}
                                                                            />
                                                                        </ListItem>
                                                                        <Divider variant="inset" component="li" />
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </List>
                                                    <DialogMaterial
                                                        open={materialOpen}
                                                        close={() => setMaterialOpen(false)}
                                                        transition={Transition}
                                                        material={materialItem}
                                                    />
                                                </Grid>
                                                : null
                                        }
                                        <ScheduleClass />
                                        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-subscription-code">
                                            <Formik
                                                validationSchema={yup.object().shape({
                                                    subscription_code: yup.string().required(() => intl.get('ERROR_REQUIRED_FIELD'))
                                                })}
                                                initialValues={{
                                                    subscription_code: ''
                                                }}
                                                onSubmit={data => sendSubscribeCode(data)}
                                            >
                                                {
                                                    ({ handleBlur, handleChange, handleSubmit, errors, touched }) => (
                                                        <Form noValidate onSubmit={handleSubmit}>
                                                            <DialogTitle>
                                                                {intl.get("CLASSROOM_SUBSCRIPTION_CODE_TITLE")}
                                                            </DialogTitle>
                                                            <DialogContent>
                                                                <DialogContentText>
                                                                    {intl.get("CLASSROOM_SUBSCRIPTION_CODE_TEXT")}
                                                                </DialogContentText>
                                                                <TextField
                                                                    autoFocus
                                                                    margin="dense"
                                                                    id="subscription_code"
                                                                    name="subscription_code"
                                                                    label={intl.get("LABEL_CODE")}
                                                                    type="text"
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    fullWidth
                                                                    required
                                                                    error={(errors.subscription_code && touched.subscription_code)}
                                                                    helperText={(errors.subscription_code && touched.subscription_code) && errors.subscription_code}
                                                                />
                                                            </DialogContent>
                                                            <DialogActions>
                                                                <Button onClick={handleClose} color="primary">
                                                                    {intl.get('BTN_CANCEL')}
                                                                </Button>
                                                                <Button color="primary" type="submit">
                                                                    {intl.get('BTN_SEND')}
                                                                </Button>
                                                            </DialogActions>
                                                        </Form>
                                                    )
                                                }
                                            </Formik>
                                        </Dialog>
                                        <SnackBar
                                            open={snackOpen}
                                            message={snackMessage}
                                            status="error"
                                            time={2}
                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                            closeSnack={() => setSnackOpen(false)}
                                        />
                                    </Grid>
                                </Container>
                            </div>
                            : classroom.status === "finished" && classroom.details.length === 0 ?
                                <Container maxWidth="md">
                                    <EmptyContent icon={<Icon ic="play_classroom" className="icon-xxl" />} text={intl.get("EMPTY_CLASSROOM")} />
                                </Container>
                                : classroom.errorLoading ?
                                    <Container maxWidth="md">
                                        <ErrorContent />
                                    </Container>
                                    :
                                    <Container maxWidth="md">
                                        <LoadingContent />
                                    </Container>
                    }
                </QrContent>
            </div>
        </div>
    )
}
