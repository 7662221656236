import React, { useState } from 'react';
import intl from 'react-intl-universal';
import ReactHtmlParser from 'react-html-parser';
import { imageParser, numberMask } from '../../../utils/utils';
import {
    Container, Grid, CardActionArea, CardMedia, Typography, LinearProgress,
} from '@material-ui/core';
import config from '../../../config';
import DialogImageQuestion from './DialogImageQuestion';
import QrExamTimer from '../../QrExamTimer';
import { Icon, FreeLevel } from '../../Images/Images';

export default function Question(props) {
    const { module, question, secretWord, stepType } = props;
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const GameModule = () => {
        switch (module) {
            case 'free':
                const DifficultyLevel = (id, qis) => {
                    switch (id) {
                        case "1":
                            return (
                                <FreeLevel type="easy" text={qis} />
                            )
                        case "2":
                            return (
                                <FreeLevel type="medium" text={qis} />
                            )
                        case "3":
                            return (
                                <FreeLevel type="hard" text={qis} />
                            )
                        default:
                            return null
                    }
                }

                return (
                    <Grid item xs={12} sm={10}>
                        <Grid container justify="center">
                            {DifficultyLevel(String(question.question.id_level), question.question.qis)}
                        </Grid>
                    </Grid>
                )
            case 'trail':
                function calcProgress(correct, total) {
                    return Math.round((correct / total) * 100)
                }

                return (
                    <Grid item xs={12} sm={10}>
                        <Grid container justify="center">
                            <div className="question-star-progress">
                                {
                                    stepType !== 'CHECK' && (config.learn.trail.type === 'stars' && question.progress?.stars_positions) ?
                                        <>
                                            {/* Star 1 */}
                                            <div
                                                className={"star" + (question.progress.right >= parseInt(question.progress.stars_positions[0]) ? " active" : "")}
                                                style={{
                                                    left: `calc(${calcProgress(parseInt(question.progress.stars_positions[0]), question.progress.total)}% - 1.125rem)`
                                                }}
                                            >
                                                <Icon ic="star_filled" className="icon-md" />
                                            </div>

                                            {/* Star 2 */}
                                            <div
                                                className={"star" + (question.progress.right >= parseInt(question.progress.stars_positions[1]) ? " active" : "")}
                                                style={{
                                                    left: `calc(${calcProgress(parseInt(question.progress.stars_positions[1]), question.progress.total)}% - 1.125rem)`
                                                }}
                                            >
                                                <Icon ic="star_filled" className="icon-md" />
                                            </div>

                                            {/* Star 3 */}
                                            <div
                                                className={"star" + (question.progress.right >= parseInt(question.progress.stars_positions[2]) ? " active" : "")}
                                                style={{
                                                    left: `calc(${calcProgress(parseInt(question.progress.stars_positions[2]), question.progress.total)}% - 1.125rem)`
                                                }}>
                                                <Icon ic="star_filled" className="icon-md" />
                                            </div>
                                        </>
                                        : null
                                }

                                {
                                    stepType === 'CHECK' || (stepType === 'EXAM' && !(config.learn.trail.type === 'stars' && question.progress?.stars_positions)) ?
                                        <div className="progress-content">
                                            <LinearProgress variant="determinate" className="progress-bar __check"
                                                value={calcProgress(question.progress.answered, question.progress.total)}
                                            />
                                        </div>
                                        :
                                        <div className="progress-content">
                                            {/* Acertos */}
                                            <LinearProgress variant="determinate" className="progress-bar __front"
                                                value={calcProgress(question.progress.right, question.progress.total)}
                                            />

                                            {/* Respondidas */}
                                            <LinearProgress variant="determinate" className="progress-bar"
                                                value={calcProgress(question.progress.answered, question.progress.total)}
                                            />
                                        </div>
                                }
                            </div>
                        </Grid>
                        <Grid container justify="center">
                            <Typography variant="body2" component="p">
                                {`${intl.get("LABEL_QUESTION")} ${parseInt(question.progress.answered) + 1} / ${question.progress.total}`}
                            </Typography>
                        </Grid>
                    </Grid>
                )
            case 'survey':
                return (
                    <Grid item xs={12} sm={10}>
                        <Grid container justify="center">
                            <Typography variant="body2" component="p">
                                {`${intl.get("LABEL_QUESTION")} ${parseInt(question.total_answered) + 1} / ${question.total_questions}`}
                            </Typography>
                        </Grid>
                    </Grid>
                )
            case 'periodic':
                return (
                    <Grid item xs={12} sm={10}>
                        <Grid container justify="center">
                            <div className="question-points">
                                <div className="question-points-content">
                                    <Typography variant="h5" component="span" className={parseInt(question.question.qis) > 999 ? "__small" : ""}>
                                        {numberMask(question.question.qis)}
                                    </Typography>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                )
            case 'exam':
                return (
                    <Grid item xs={12} sm={10}>
                        <QrExamTimer
                            startDate={question.user_start_date}
                            finishDate={question.user_limit_finish_date}
                            serverDate={props.serverDate}
                            timeOut={(t) => props.timeOut(t)}
                            currentQuestion={question.current_question}
                            totalQuestion={question.total_question}
                        />
                    </Grid>
                )
            case 'feedback':
                return (
                    <Grid item xs={12} sm={10}>
                        <Grid container justify="center">
                            <Typography variant="body2" component="p">
                                {`${intl.get("LABEL_QUESTION")}`}
                            </Typography>
                        </Grid>
                    </Grid>
                )
            default:
                return "Nenhum módulo selecionado"
        }
    }

    const QuestionContent = () => {
        return (
            <Grid item xs={12} sm={10}>
                <Grid container className="qr-game-question-content" alignItems="center" justify="center" spacing={2}>

                    {/* Se houver imagem e NÃO for abaixo da pergunta: */}
                    {
                        question.question.question_type !== "QUIZ_IMAGE_DOWN" && (question.question.image && question.question.image.blob)
                            ?
                            <Grid item xs={12} sm={10}>
                                <CardActionArea
                                    onClick={handleClickOpen}
                                    className="rounded-lg"
                                >
                                    <CardMedia
                                        component="img"
                                        image={imageParser(question.question.image)}
                                        className="image-question img-fluid"
                                        title="Question"
                                    />
                                </CardActionArea>
                            </Grid>
                            : null
                    }

                    {/* Texto da pergunta */}
                    <Grid item xs={12}>
                        <Typography variant="h5" component="div">
                            {ReactHtmlParser(question.question.question)}
                        </Typography>
                    </Grid>

                    {/* Se houver imagem e for abaixo da pergunta: */}
                    {
                        question.question.question_type === "QUIZ_IMAGE_DOWN" && (question.question.image && question.question.image.blob)
                            ?
                            <Grid item xs={12} sm={10}>
                                <CardActionArea
                                    onClick={handleClickOpen}
                                    className="rounded-lg"
                                >
                                    <CardMedia
                                        component="img"
                                        image={imageParser(question.question.image)}
                                        className="image-question img-fluid"
                                        title="Question"
                                    />
                                </CardActionArea>
                            </Grid>
                            : null
                    }

                    {/* Exibição da palavra secreta digitada pelo usuário */}
                    {
                        question.question.question_type === 'FILL_WORD'
                            ?
                            <Grid item xs={12}>
                                <Grid container className="qr-game-question-secret-word" justify="center" spacing={2}>
                                    {
                                        question.question.character_length.map((num, i) => {
                                            return (
                                                <div key={i} className="word-content">
                                                    <Grid container justify="center">
                                                        {
                                                            secretWord.length !== 0 ?
                                                                secretWord[i].map((item, j) => {
                                                                    return (
                                                                        <div key={j} className="letter">
                                                                            <Typography variant="h3" component="span">
                                                                                {item}
                                                                            </Typography>
                                                                        </div>
                                                                    )
                                                                })
                                                                : []
                                                        }
                                                    </Grid>
                                                </div>
                                            )
                                        })
                                    }
                                </Grid>
                            </Grid>
                            : null
                    }
                </Grid>
            </Grid>
        )
    }

    return (
        <div className="qr-game-question appbar-custom-content">
            <Container maxWidth="md">
                <Grid container justify="center" spacing={2}>
                    <GameModule />
                    <QuestionContent />
                </Grid>
            </Container>
            {
                question.question.image
                    ?
                    <DialogImageQuestion
                        open={open}
                        close={handleClose}
                        image={question.question.image}
                    />
                    :
                    null
            }
        </div>
    )

}
