import React, { useEffect, forwardRef, useState } from 'react';
import intl from 'react-intl-universal';
import countries from 'i18n-iso-countries';
import config from '../../config';
import useReactRouter from 'use-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { actions as PrizesActions } from '../../redux/ducks/prizes';
import ReactHtmlParser from 'react-html-parser';
import {
    Grid, AppBar, Toolbar, IconButton, Typography, Container, Divider,
    Button, Slide, Dialog, DialogActions, DialogContent, DialogContentText,
    Box, Link, Stepper, Step, StepLabel, FormControl, FormGroup, FormControlLabel,
    Checkbox, Card, CardActionArea, CardContent, RadioGroup, Radio
} from '@material-ui/core';
import { imageParser, numberMask } from '../../utils/utils';
import { QrMenu } from '../../components/QrMenu';
import QrContent from '../../components/QrContent';
import EmptyContent from '../../components/EmptyContent';
import ErrorContent from '../../components/ErrorContent';
import { Icon, Points } from '../../components/Images/Images';
import { LoadingContent } from '../../components/Loading';
import SnackBar from '../../components/snackBar';

countries.registerLocale(require("i18n-iso-countries/langs/pt.json"));
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/es.json"));

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function PrizeDetails(props) {
    const dispatch = useDispatch();
    const { history } = useReactRouter();
    const user = useSelector(state => state.auth.user);
    const details = useSelector(state => state.prizes.details);
    const redeem = useSelector(state => state.prizes.redeem);
    const bookmark = useSelector(state => state.prizes.bookmark);
    const [didMount, setDidMount] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackStatus, setSnackStatus] = useState("error");
    const [snackMessage, setSnackMessage] = useState(intl.get('ERROR_REDEEM'));
    const [loadItem, setLoadItem] = useState(false);
    const [bookmarked, setBookmarked] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [checked, setChecked] = useState(false);
    const [selectAddress, setSelectAddress] = useState("");
    const [activeStep, setActiveStep] = useState(0);
    const origin = props.location.state && props.location.state.origin ? props.location.state.origin : '';
    let language = user.language.substr(0, 2);

    useEffect(() => {
        setDidMount(true);

        if (didMount) {
            dispatch(PrizesActions.getPrizeDetails({
                id_prize: props.match.params.idPrize
            }));
        }

        return () => {
            dispatch(PrizesActions.reset());
            setDidMount(false);
        }
    }, [didMount, dispatch, props.match]);

    useEffect(() => {
        if (!loadItem && details?.load === "finished") {
            setLoadItem(true);
            setBookmarked(details.data.prize.bookmarked === "Y");
            setSelectAddress(details.data.list_address[0].id_address);
        }
    }, [loadItem, dispatch, details]);

    function goBack() {
        let currentOrigin = "prize_details";

        switch (origin) {
            case 'list':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/prizes`,
                    state: {
                        origin: currentOrigin
                    }
                })
            case 'redeem':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/prizes`,
                    state: {
                        origin: currentOrigin
                    }
                })
            case 'bookmark_list':
                return history.push({
                    pathname: `${props.match.path.replace(/./g, '')}/prizes/bookmarks`,
                    state: {
                        origin: currentOrigin
                    }
                })
            default:
                return history.goBack()
        }
    }

    const QrAppBar = () => {
        return (
            <AppBar className="appbar appbar-overlay" position="absolute">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="back"
                        edge="start"
                        onClick={() => goBack()}
                    >
                        <Icon ic="back" />
                    </IconButton>
                    <div className="w-100 overflow-hidden"></div>
                </Toolbar>
            </AppBar>
        )
    }

    const MessagesRedeem = () => {
        switch (details.data.prize.messages[0]) {
            case 'USER_INCOMPLETE_INFORMATION':
                return (
                    <Box p={2} className="highlight-box">
                        <Grid container alignItems="center">
                            <Link
                                onClick={() => history.push({
                                    pathname: `${props.match.path.replace(/./g, '')}/settings/data-redeem/info`,
                                    state: {
                                        origin: "prize_details"
                                    }
                                })}
                                className="cursor-pointer"
                            >
                                <Typography variant="body2" component="p" className="fs-md" align="center">
                                    {details.data.prize.localized_messages[0]}
                                </Typography>
                            </Link>
                        </Grid>
                    </Box>
                )
            case 'USER_INCOMPLETE_INFORMATION_ADDRESS':
                return (
                    <Box p={2} className="highlight-box">
                        <Grid container alignItems="center">
                            <Link
                                onClick={() => history.push({
                                    pathname: `${props.match.path.replace(/./g, '')}/settings/data-redeem/address`,
                                    state: {
                                        origin: "prize_details"
                                    }
                                })}
                                className="cursor-pointer"
                            >
                                <Typography variant="body2" component="p" className="fs-md" align="center">
                                    {details.data.prize.localized_messages[0]}
                                </Typography>
                            </Link>
                        </Grid>
                    </Box>
                )
            case 'REDEEM_LIMIT_EXCEEDED':
                return (
                    <Box p={2} className="highlight-box">
                        <Typography variant="body2" component="p" className="fs-md" align="center">
                            {details.data.prize.localized_messages[0]}
                        </Typography>
                    </Box>
                )
            case 'PRIZE_OUT_OF_STOCK':
                return (
                    <Box p={2} className="highlight-box">
                        <Typography variant="body2" component="p" className="fs-md" align="center">
                            {details.data.prize.localized_messages[0]}
                        </Typography>
                    </Box>
                )
            case 'USER_INSUFFICIENT_QI':
                return (
                    <Box p={2} className="highlight-box">
                        <Typography variant="body2" component="p" className="fs-md" align="center">
                            {details.data.prize.localized_messages[0]}
                        </Typography>
                    </Box>
                )
            case 'PREMIUM_PRIZE':
                return (
                    <Box p={2} className="highlight-box">
                        <Typography variant="body2" component="p" className="fs-md" align="center">
                            {details.data.prize.localized_messages[0]}
                        </Typography>
                    </Box>
                )
            case 'REDEEM_LIMIT_PER_PARTNER_EXCEEDED':
                return (
                    <Box p={2} className="highlight-box">
                        <Typography variant="body2" component="p" className="fs-md" align="center">
                            {details.data.prize.localized_messages[0]}
                        </Typography>
                    </Box>
                )
            case 'USER_REDEEMED_UNIQUE_PRIZE':
                return (
                    <Box p={2} className="highlight-box">
                        <Typography variant="body2" component="p" className="fs-md" align="center">
                            {details.data.prize.localized_messages[0]}
                        </Typography>
                    </Box>
                )
            case 'USER_REDEEMED_PRIZE_FROM_THIS_PARTNER_IN_60_DAYS':
                return (
                    <Box p={2} className="highlight-box">
                        <Typography variant="body2" component="p" className="fs-md" align="center">
                            {details.data.prize.localized_messages[0]}
                        </Typography>
                    </Box>
                )
            case 'PRIZE_PERIOD_NOT_AVAILABLE':
                return (
                    <Box p={2} className="highlight-box">
                        <Typography variant="body2" component="p" className="fs-md" align="center">
                            {details.data.prize.localized_messages[0]}
                        </Typography>
                    </Box>
                )
            case 'PRIZE_REDEEM_INTERVAL':
                return (
                    <Box p={2} className="highlight-box">
                        <Typography variant="body2" component="p" className="fs-md" align="center">
                            {details.data.prize.localized_messages[0]}
                        </Typography>
                    </Box>
                )
            case 'PRIZE_NOT_AVAILABLE':
                return (
                    <Box p={2} className="highlight-box">
                        <Typography variant="body2" component="p" className="fs-md" align="center">
                            {details.data.prize.localized_messages[0]}
                        </Typography>
                    </Box>
                )
            case 'PRIZE_AGE_LIMIT':
                return (
                    <Box p={2} className="highlight-box">
                        <Typography variant="body2" component="p" className="fs-md" align="center">
                            {details.data.prize.localized_messages[0]}
                        </Typography>
                    </Box>
                )
            default:
                return null;
        }
    }

    const Header = () => {
        return (
            <div id="prizes-details-header"
                style={{
                    backgroundImage: `url(${imageParser(details.data.prize.image)})`
                }}
            >
            </div>
        )
    }

    useEffect(() => {
        if (loading && (redeem?.redeemed === true)) {
            history.push({
                pathname: `${props.match.path.replace(/./g, '')}/prizes/${details.data.prize.id_prize}/redeem`,
                state: { origin: 'prize' }
            })
        }

        if (loading && (redeem?.redeemed === false)) {
            setLoading(false);
            setSnackStatus('error');
            setSnackOpen(true);
            setSnackMessage(redeem.messages);
            dispatch(PrizesActions.resetPrizeRedeem({}));
        }
    }, [loading, redeem, details, props.match.path, history, dispatch]);

    async function setRedeem() {
        setLoading(true);
        setOpenDialog(false);
        dispatch(PrizesActions.setRedeem({
            id_prize: props.match.params.idPrize,
            id_address: selectAddress
        }));
    }

    async function setbookmark(status) {
        setBookmarked(status);
        setClicked(true);
        dispatch(PrizesActions.setBookmark({
            id_prize: details.data.prize.id_prize,
            favorited: status ? "Y" : "N"
        }))
    }

    useEffect(() => {
        if (clicked && bookmark?.error) {
            setClicked(false);
            setBookmarked(!bookmarked);
            setSnackStatus('error');
            setSnackOpen(true);
            setSnackMessage(intl.get('ERROR_BOOKMARK'));
            dispatch(PrizesActions.resetBookmark({}));
        }
    }, [dispatch, clicked, bookmark, bookmarked]);

    const countryList = Object.keys(countries.getNames(language)).map((country) => {
        return {
            name: countries.getName(country, language),
            code: countries.getAlpha2Code(countries.getName(country, language), language)
        }
    });

    const StepContent = ({ step }) => {
        switch (step) {
            case 0:
                return (
                    <Grid container>
                        <Grid item xs={12}>
                            <DialogContentText id="dialog-redeem-conditions">
                                {ReactHtmlParser(details.data.prize.conditions)}
                            </DialogContentText>
                            <FormControl component="fieldset">
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox color="primary" checked={checked} onChange={handleCheck} name="prize_conditions" />}
                                        label={intl.get('PRIZES_DIALOG_REDEEM_CHECKBOX')}
                                        inputprops={{ 'aria-label': intl.get('PRIZES_DIALOG_REDEEM_CHECKBOX') }}
                                    />
                                </FormGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                )
            case 1:
                return (
                    <RadioGroup aria-label="address" name="address" value={selectAddress} onChange={handleAddress}>
                        <Grid container spacing={2}>
                            {
                                details.data.list_address.map((item, index) => {
                                    return (
                                        <Grid key={index} item xs={12}>
                                            <Card className="card-address">
                                                <CardActionArea onClick={() => setSelectAddress(item.id_address)}>
                                                    <CardContent className="d-flex align-items-center">
                                                        <div>
                                                            <FormControlLabel value={item.id_address} control={<Radio color="primary" />} />
                                                        </div>
                                                        <div className="w-100">
                                                            <Typography variant="body1" component="h2" className="mb-3">
                                                                {item.main_address === "Y" ? intl.get("LABEL_MAIN_ADDRESS") : `${intl.get("LABEL_SECONDARY_ADDRESS")} ${index}`}
                                                            </Typography>
                                                            <Divider />
                                                            <Typography variant="body2" component="p" className="mt-3">
                                                                {`${item.address}, ${item.number} - ${item.neighborhood}`}
                                                            </Typography>
                                                            {
                                                                item.complement ?
                                                                    <Typography variant="body2" component="p">
                                                                        {`${intl.get("LABEL_COMPLEMENT")}: ${item.complement}`}
                                                                    </Typography>
                                                                    : null
                                                            }
                                                            <Typography variant="body2" component="p">
                                                                {`${item.city}, ${item.state}`}
                                                            </Typography>
                                                            <Typography variant="body2" component="p">
                                                                {`${intl.get("LABEL_ZIP_CODE")}: ${item.zip_code}`}
                                                            </Typography>
                                                            <Typography variant="body2" component="p">
                                                                {countryList.find(country => country.code === item.country).name}
                                                            </Typography>
                                                        </div>
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                    )
                                })

                            }
                        </Grid>
                    </RadioGroup>
                )
            default:
                return null;
        }
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleCheck = (e) => {
        setChecked(e.target.checked);
    };

    const handleAddress = (e) => {
        setSelectAddress(e.target.value);
    };

    return (
        <div id="qr-wrap">
            <QrMenu />
            <div id="qr-content">
                <QrContent id="prizes-details" hideSidebar>
                    {
                        config.layout.navbar ?
                            <QrAppBar />
                            : null
                    }
                    {
                        details && details.load === "finished" ?
                            !details.error ?
                                details.data.length !== 0 ?
                                    !loading ?
                                        <div>
                                            {/* Header */}
                                            <Header />

                                            {/* Title */}
                                            <Container maxWidth="md" className="prize-info pt-4 pb-3">
                                                <Grid container justify="center" spacing={1}>
                                                    <Grid item xs={12} sm={10}>
                                                        <Typography variant="h4" component="h1">
                                                            {details.data.prize.name}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item container xs={12} sm={10} alignItems="center" justify="space-between" className="flex-nowrap">
                                                        <div className="mr-3">
                                                            <Typography variant="body1" component="p">
                                                                {details.data.prize.company}
                                                            </Typography>
                                                            <Typography variant="body2" component="p" className="mt-2">
                                                                {details.data.prize.available}
                                                            </Typography>
                                                        </div>
                                                        <IconButton color={`${bookmarked ? "primary" : "inherit"}`} aria-label={intl.get('LABEL_BOOKMARK')} onClick={() => setbookmark(!bookmarked)}>
                                                            <Icon ic={`${bookmarked ? "bookmark_filled" : "bookmark_outline"}`} />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </Container>

                                            {/* Price */}
                                            <div className="prize-price">
                                                <Container maxWidth="md" >
                                                    <Grid container justify="center">
                                                        <Grid item xs={12} sm={10} className="d-flex align-items-center">
                                                            <Points type="qis" size="lg" />
                                                            <Typography variant="h4" component="p" className="text-qis ml-1">
                                                                {numberMask(details.data.prize.qi)}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Container>
                                            </div>

                                            {/* Button Redeem */}
                                            <div className="prize-redeem">
                                                <Container maxWidth="md" >
                                                    <Grid container justify="center" spacing={2}>
                                                        <Grid item xs={12} sm={10}>
                                                            <Typography variant="body2" component="p" align="center">
                                                                {details.data.prize.stock}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={8} lg={4}>
                                                            {
                                                                details.data.prize.messages?.length > 0 ?
                                                                    <MessagesRedeem />
                                                                    : details.data.prize.redeemable === "Y" ?
                                                                        <Button
                                                                            id="btn_prize_redeem"
                                                                            variant="contained"
                                                                            className="btn-block"
                                                                            onClick={() => setOpenDialog(true)}
                                                                        >
                                                                            <Icon ic="shopping_cart_filled" className="mr-2" /> {intl.get("BTN_REDEEM")}
                                                                        </Button>
                                                                        :
                                                                        <Grid item xs={12} sm={8} lg={4}>
                                                                            <Box p={2} className="highlight-box">
                                                                                <Grid container alignItems="center" justify="center">
                                                                                    <Icon ic="unavailable" color="lighter" className="icon-xs mr-2" />
                                                                                    <Typography variant="body1" component="p" className="text-lighter">
                                                                                        {intl.get("PRIZES_DETAILS_PRIZE_UNAVAILABLE")}
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Box>
                                                                        </Grid>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Container>
                                            </div>

                                            {/* Info */}
                                            <Container maxWidth="md" className="prize-info pb-4">
                                                {
                                                    details.data.prize.about ?
                                                        <Grid container justify="center" spacing={1} className="pt-3">
                                                            <Grid item xs={12} sm={10}>
                                                                <Typography variant="body1" component="h4" className="text-primary">
                                                                    {intl.get("LABEL_DESCRIPTION")}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={10}>
                                                                <Typography variant="body2" component="div">
                                                                    {ReactHtmlParser(details.data.prize.about)}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                        : null
                                                }

                                                {
                                                    details.data.prize.conditions ?
                                                        <Grid container justify="center" spacing={1} className="pt-3">
                                                            <Grid item xs={12} sm={10}>
                                                                <Typography variant="body1" component="h4" className="text-primary">
                                                                    {intl.get("LABEL_CONDITIONS")}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={10}>
                                                                <Typography variant="body2" component="div">
                                                                    {ReactHtmlParser(details.data.prize.conditions)}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                        : null
                                                }
                                            </Container>

                                            {/* Dialog Redeem */}
                                            <Dialog
                                                fullWidth
                                                maxWidth="sm"
                                                open={openDialog}
                                                TransitionComponent={Transition}
                                                onClose={() => setOpenDialog(false)}
                                                aria-labelledby="dialog-redeem-title"
                                                aria-describedby="dialog-redeem-conditions"
                                            >
                                                <DialogContent>
                                                    <Stepper activeStep={activeStep} alternativeLabel>
                                                        <Step key={intl.get("LABEL_CONDITIONS")}>
                                                            <StepLabel>{intl.get("LABEL_CONDITIONS")}</StepLabel>
                                                        </Step>
                                                        <Step key={intl.get("LABEL_ADDRESS")}>
                                                            <StepLabel>{intl.get("LABEL_ADDRESS")}</StepLabel>
                                                        </Step>
                                                    </Stepper>
                                                    <StepContent step={activeStep} />
                                                </DialogContent>
                                                {
                                                    activeStep === 0 ?
                                                        <DialogActions>
                                                            <Button onClick={() => setOpenDialog(false)} color="primary">
                                                                {intl.get("BTN_CANCEL")}
                                                            </Button>
                                                            <Button
                                                                disabled={!checked}
                                                                onClick={handleNext}
                                                                color="primary"
                                                            >
                                                                {intl.get("BTN_NEXT")}
                                                            </Button>
                                                        </DialogActions>
                                                        :
                                                        <DialogActions>
                                                            <Button onClick={handleBack} color="primary">
                                                                {intl.get("BTN_BACK")}
                                                            </Button>
                                                            <Button
                                                                onClick={() => setRedeem()}
                                                                color="primary"
                                                            >
                                                                {intl.get("BTN_CONFIRM")}
                                                            </Button>
                                                        </DialogActions>
                                                }

                                            </Dialog>
                                        </div>
                                        :
                                        <Container maxWidth="md" >
                                            <LoadingContent />
                                        </Container>
                                    :
                                    <Container maxWidth="md" >
                                        <EmptyContent icon={<Icon ic="gift_filled" className="icon-xxl" />} text={intl.get("EMPTY_PRIZES")} />
                                    </Container>
                                :
                                <Container maxWidth="md" >
                                    <ErrorContent />
                                </Container>
                            :
                            <Container maxWidth="md" >
                                <LoadingContent />
                            </Container>
                    }
                </QrContent>
                <SnackBar
                    open={snackOpen}
                    message={snackMessage}
                    status={snackStatus}
                    time={4}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    closeSnack={() => setSnackOpen(false)}
                />
            </div>
        </div>
    )
}